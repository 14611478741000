<template>
  <v-container fluid style="background: #fff !important; margin-top: -60px">
    <div v-if="isloading" class="text-align-center">
      Generating invoice details...
    </div>
    <div v-if="!isloading">
      <header class="header">
        <div class="__in">
          <table no-border width="100%">
            <tr>
              <td>
                <div class="logo text-left">
                  <img
                    src="https://s3.amazonaws.com/truckkinapi/profilePic_10004053.jpg"
                    style="width: 140px"
                  />
                </div>
              </td>
              <td>
                <div>
                  <h3
                    class="gray-text"
                    style="text-align: end; margin-top: -10px"
                  >
                    INVOICE DETAILS<br />
                    تفاصيل الفاتورة
                  </h3>
                </div>
              </td>
              <!-- <td>
              <div
                class="text-right __invoice"
                style="color: #333; font-family: Oswald, sans-serif"
              >
                <div>Invoice No</div>
                <div>
                  رقم الفاتورة
                  <div>
                    <div>TRDMM-FY1910-0015</div>
                  </div>
                </div>
              </div>
            </td> -->
            </tr>
          </table>
        </div>
      </header>
      <main>
        <div>
          <div class="__devider">
            <table
              border
              cellspacing="0"
              cellpadding="10"
              style="
                margin: auto;
                margin-top: 50px;
                text-align: left;
                font-size: 16px;
              "
            >
              <tr>
                <th>Invoice No.</th>
                <td>{{ invoiceDetails.invoiceNumber || "N.A" }}</td>
              </tr>

              <tr>
                <th>Seller</th>
                <td>{{ invoiceDetails.customer || "N.A" }}</td>
              </tr>
              <tr>
                <th>VAT No.</th>
                <td>{{ invoiceDetails.vatNo || "N.A" }}</td>
              </tr>
              <tr>
                <th>Timestamp</th>
                <td>
                  {{ getTime3(invoiceDetails.invoiceGeneratedAt) || "N.A" }}
                </td>
              </tr>
              <tr>
                <th>VAT Total</th>
                <td>{{ invoiceDetails.vatTotal || "N.A" }}</td>
              </tr>
              <tr>
                <th>Invoice Total</th>
                <td>{{ invoiceDetails.invoiceTotal || "N.A" }}</td>
              </tr>
            </table>
          </div>
        </div>
      </main>
      <!-- <footer style="position: absolute; bottom: 0">
      <div class="__relative">
        <div class="__footer">
          <p class="footer-p">
            Services : UAE | KSA | Pakistan | Kuwait | Bahrain | Oman | Qatar |
            Jordan | Egypt | Iraq | Lebanon<br />
            <span style="display: inline-block; padding-right: 20px"
              >خدمات :</span
            >الإمارات | السعودية | باكستان | الكويت | البحرين | عمان | قطر |
            الأردن | مصر | العراق | لبنان
          </p>
          <span class="__f_right">
            Page1<br />
            صفحة
          </span>
        </div>
      </div>
    </footer> -->
    </div>
  </v-container>
</template>

<script>
import { ApiService } from "@/services/api-service";
import moment from "moment";
export default {
  created() {
    this.getDetails();
    this.$eventBus.$emit("show-nav-items", false);
  },
  data() {
    return {
      invoiceDetails: {},
      isloading: false,
    };
  },
  methods: {
    getTime3(date) {
      return moment.unix(date).format("MM/DD/YYYY HH:mm");
    },
    getDetails() {
      this.isloading = true;
      let url = `/invoice-verification?invoiceNumber=${this.$route.query.invoiceNumber}`;
      ApiService.getInvoiceDetails(url).then(
        (response) => {
          this.isloading = false;
          this.invoiceDetails = { ...response.data.data };
        },
        (error) => {
          this.isloading = false;
          const message = this.$eventBus.parse(error);
          alert(message);
        }
      );
    },
  },
};
</script>

<style scoped>
@page {
  margin: 0;
  padding: 0;
}
table tr th,
table tr td {
  padding: 10px;
}

body {
  font-size: 10px;
  color: #333;
}

footer {
  bottom: 40px;
  left: 0;
  right: 0;
}

.__invoice {
  float: right;
}

.text-right {
  text-align: right !important;
}

.gray-text {
  font-size: 25px;
  color: #d2d2d2;
  font-weight: 800;
  font-family: Oswald, sans-serif;
  margin-bottom: 0;
  margin-top: 0px;
}

.logo.text-left {
  float: left;
}

.__in {
  display: inline-block;
  width: 100%;
}

header.header {
  margin-bottom: 0px;
}

.__devider {
  padding: 8px 0;
  border-top: 1.5px solid #373737;
  /* border-bottom: 1.5px solid #373737; */
  font-family: Oswald, sans-serif;
}

.footer-p {
  padding-top: 4px;
  float: left;
  font-size: 6px;
}

.__footer {
  background: #202020;
  color: #fff;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: Montserrat !important;
  font-size: 9px;
  height: 32px;
  text-align: left;
  padding-left: 10px;
}

.__f_right {
  height: 100%;
  background: #fff;
  color: #000;
  padding-left: 10px;
  padding-right: 10px;
  float: right;
  border-top: 1px solid #000;
  padding-top: 0px;
  font-size: 7px;
  line-height: 10px;
  display: inline-block;
}
</style>
