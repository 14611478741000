<template>
  <v-container fluid style="background: #fff !important; margin-top: -60px">
    <div v-if="isloading" class="text-align-center">
      Generating Waybill/TCN...
    </div>
    <div class="print-link" v-if="!isloading">
      <v-btn @click.prevent="print" color="orange darken-2" class="white--text"
        >Print
      </v-btn>
    </div>

    <div id="waybillPage" v-if="!isloading">
      <VueBarcode
        v-if="waybill.customerId == 'CUS1'"
        v-bind:value="waybill.assignmentId"
        class="bar-code-style"
        :displayValue="'false'"
        width="1"
        height="50"
      >
      </VueBarcode>

      <br />

      <v-layout
        style="
          padding: 2px 10px;
          border-bottom: 5px solid #f57c00 !important;
          margin-bottom: 15px;
          color: #f57c00;
          font-weight: bold;
        "
      >
        <v-flex xs3 style="font-size: 16px">
          <img src="../../assets/v_logo_big.png" height="65" width="145" />
        </v-flex>
        <v-flex xs6>
          <div
            style="
              margin: 0px auto;
              width: 80%;
              text-align: center;
              margin-top: -10px;
            "
          >
            <div v-if="country == 'UAE'" style="font-size: 24px">
              شركة تراكن للنقل & ووساطة الشحن
            </div>
            <div
              v-if="country == 'UAE'"
              style="font-size: 17px; margin-top: -10px"
              class="bold py-1"
            >
              Trukkin Transporter & Freight Broker
            </div>

            <div v-if="country == 'KSA'" style="font-size: 24px">
              شركة تركين العربية السعودية المحدودة
            </div>
            <div
              v-if="country == 'KSA'"
              style="font-size: 17px; margin-top: -10px"
              class="bold py-1"
            >
              Trukkin Saudi Arabia LTD
            </div>
          </div>
        </v-flex>
        <v-flex xs3 style="font-size: 23px; text-align: right">
          <div class="body-1" style="font-size: 15px !important">
            Riyadh: +966569806711 / +966567231193
            <br />Dubai: +971563681471 / +971543680337 <br />E-mail:
            contact@Trukkin.com
          </div>
        </v-flex>
      </v-layout>
      <v-layout style="padding: 8px 10px">
        <!-- <v-flex xs4 style="font-size:12px;font-size: 15px;font-weight:900;">Job ID - {{waybill.jobId || 'N.A.'}}</v-flex> -->
        <v-flex style="font-size: 14px; font-weight: bold" xs4>
          <v-layout>
            <v-flex xs4>Job ID:</v-flex>
            <v-flex class="text-left" xs4> {{ waybill.jobId || "" }} </v-flex>
            <v-flex class="text-left" xs4>معرف الوظيفة</v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs1></v-flex>
        <v-flex style="font-size: 14px; font-weight: bold" xs3>
          <v-layout>
            <v-flex xs4>Waybill:</v-flex>
            <v-flex class="text-left" xs4>
              {{ waybill.assignmentId || "" }}
            </v-flex>
            <v-flex class="text-left" xs4>البوليصة</v-flex>
          </v-layout>
        </v-flex>

        <v-flex
          v-if="waybill.wayBillSequenceNo"
          style="font-size: 14px; font-weight: bold"
          xs4
        >
          <v-layout>
            <v-flex class="text-right" xs4>UNIQUE NO.:</v-flex>
            <v-flex class="text-right" xs4>
              {{ waybill.wayBillSequenceNo || "" }}
            </v-flex>
            <v-flex class="text-right" xs4>رقم فريد</v-flex>
          </v-layout>
        </v-flex>
        <!-- <v-flex v-if="waybill.wayBillSequenceNo" xs4 style="font-size:12px; text-align: right;font-weight:bold;"> - {{waybill.wayBillSequenceNo}}</v-flex> -->
      </v-layout>
      <div class="divider" />

      <v-layout class="miscbox" row wrap justify-center align-center>
        <v-flex xs4>
          <waybill-detail-content
            engw="10"
            araw="10"
            eng="Date"
            ara="التاريخ"
            :val="timestamp(waybill.pickupDate)"
            noborder
          />
        </v-flex>
        <v-flex xs8>
          <!-- <waybill-detail-content
            engw="80"
            araw="50"
            eng="Transporter Policy No"
            ara="رقم بوليصة الناقل "
            :val="waybill.transporterPolicyNo"
            noborder
          /> -->
        </v-flex>
      </v-layout>
      <v-layout class="miscbox" row wrap justify-center align-center>
        <v-flex xs6>
          <!--     Changed here Rishi     -->
          <waybill-detail-content
            engw="60"
            araw="80"
            eng="Trukkin S. No"
            ara="الرقم التسلسلي"
            :val="waybill.SNo"
            noborder
          />
        </v-flex>
        <v-flex xs6>
          <waybill-detail-content
            engw="60"
            araw="100"
            eng="Shipment No."
            ara="رقم الشحنة"
            noborder
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex class="miscbox" row>
          <waybill-detail-content
            engww="10"
            araw="40"
            fullwidth
            eng="Consignor"
            ara="المرسل"
            noborder
            :val="waybill.consignee"
          />
          <waybill-detail-content
            engww="10"
            araw="80"
            fullwidth
            eng="Address"
            ara="عنوان ا لمرسل"
            noborder
            :val="waybill.consignorAddress"
          />
          <!-- <waybill-detail-content
            engww="10"
            araw="80"
            fullwidth
            eng="ID No"
            ara="رقم الهوية / الإقامة."
            noborder
            :val="waybill.idNo"
          /> -->
        </v-flex>
        <v-flex class="miscbox" xs6>
          <waybill-detail-content
            engw="10"
            araw="40"
            fullwidth
            eng="Consignee"
            ara="ا لمرسل إليه"
            noborder
            :val="waybill.consignor"
          />
          <waybill-detail-content
            engw="10"
            araw="80"
            fullwidth
            eng="Address"
            ara="عنوان ا لمرسل إليه"
            noborder
            :val="waybill.consigneeAddress"
          />
          <waybill-detail-content
            engw="0"
            araw="10"
            fullwidth
            eng="Phone"
            ara="هاتف"
            noborder
            :val="waybill.consignorPhone"
          />
          <!-- <waybill-detail-content
            engw="60"
            araw="60"
            fullwidth
            eng="Shipment No."
            noborder
            ara="رقم الشحنة"
            :val="waybill.assignmentId1"
          /> -->
        </v-flex>
      </v-layout>
      <v-layout class="miscbox" row wrap>
        <v-flex xs6>
          <waybill-detail-content
            engww="10"
            araw="80"
            fullwidth
            eng="ID No"
            ara="رقم الهوية / الإقامة."
            noborder
            :val="waybill.idNo"
          />
        </v-flex>

        <!-- <v-flex xs6>
          
          <waybill-detail-content
            engww="12"
            araww="120"
            eng="Truck No"
            ara="رقم الشاحنة"
            :val="waybill.truckNo"
            noborder
          />
        </v-flex> -->
        <v-flex xs6>
          <waybill-detail-content
            engw="10"
            araw="70"
            class="f-14"
            eng="Driver Name"
            ara="أسم السائق "
            :val="waybill.driverName"
            noborder
          />
        </v-flex>

        <v-flex xs6>
          <waybill-detail-content
            engww="0"
            araww="30"
            eng="Truck No"
            ara="رقم الشاحنة"
            :val="waybill.truckNo"
            noborder
          />
        </v-flex>
        <v-flex xs6>
          <waybill-detail-content
            engw="20"
            araw="40"
            eng="GPS No"
            ara="رقم GPS "
            :val="waybill.imeiNumber"
            noborder
          />
        </v-flex>

        <!-- <v-flex xs6>
          <waybill-detail-content
            engw="40"
            araw="40"
            class="f-14"
            eng="Driver Name"
            ara="أسم السائق "
            :val="waybill.driverName"
            noborder
          />
        </v-flex> -->

        <v-flex xs12>
          <waybill-detail-content
            engw="0"
            araw="0"
            noborder
            class="f-14"
            eng="Driver Contact No"
            ara="رقم الاتصال بالسائق"
            :val="waybill.driverContactNo"
          />
          <waybill-detail-content
            engw="40"
            araw="80"
            noborder
            eng="Operation Contact No"
            ara="رقم الإتصال بمسئول التشغيل"
            :val="
              country == 'KSA'
                ? '+966-569806711 | +966-567231193'
                : '+971-563681471 | +971-543680337'
            "
          />
          <waybill-detail-content
            engw="0"
            araw="0"
            noborder
            eng="Transporter Name"
            ara="إسم الناقل"
            :val="waybill.transporterName"
          />
        </v-flex>
      </v-layout>
      <!-- <div class="my-3 px-2" style="text-decoration: underline">Additional Details</div> -->
      <v-layout class="miscbox" column>
        <v-flex xs12>
          <waybill-detail-content
            engw="0"
            araw="0"
            noborder
            class="py-1"
            eng="Additional Notes :-"
            fullwidth
            ara="ملاحظات إضافية"
            :val="waybill.additionalNotes"
          />
        </v-flex>
        <v-flex xs12>
          <waybill-detail-content
            engw="0"
            araw="0"
            noborder
            class="py-1"
            eng="Additional Details :-"
            fullwidth
            ara="تفاصيل إضافية"
            :val="waybill.additionalDetails"
          />
        </v-flex>
      </v-layout>
      <div class="divider" />
      <v-layout row>
        <v-flex class="miscbox" row>
          <div class="heading" style="font-weight: bold; padding: 10px" xs6>
            <div v-if="country == 'UAE'">
              Trukkin Transporter & Freight Broker
            </div>
            <div v-if="country == 'UAE'" class="text-align-right">
              شركة تراكن للنقل & ووساطة الشحن
            </div>

            <div v-if="country == 'KSA'">Trukkin Saudi Arabia LTD</div>
            <div v-if="country == 'KSA'" class="text-align-right">
              شركة تركين العربية السعودية المحدودة
            </div>
          </div>
        </v-flex>
        <v-flex class="miscbox" xs10>
          <v-layout>
            <v-flex class="heading bold pb-2" style="padding: 10px" xs7
              >Cargo received in good condition</v-flex
            >
            <v-flex
              class="heading bold pb-2 text-align-right"
              style="padding: 10px"
              xs5
              >الشحنة سليمة</v-flex
            >
          </v-layout>
          <v-layout column>
            <waybill-detail-content
              engw="70"
              araw="40"
              eng="Receiver Name"
              class
              noborder
              fullwidth
              ara="إسم المستلم"
            />
            <waybill-detail-content
              engw="0"
              araw="0"
              eng="Signature"
              class
              noborder
              fullwidth
              ara="التوقيع"
            />
            <waybill-detail-content
              engw="0"
              araw="0"
              eng="Stamp"
              noborder
              fullwidth
              ara="الختم"
            />
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
    <v-layout column id="waybillcontent" v-if="!isloading">
      <v-flex class="text-align-left pdd" style="font-size: 14px">
        <span style="font-size: 16px">Waybill/TCN generated by -</span>
        Employee ID -
        <span class="contentdisplay">{{ waybill.employeeID }}</span>
        Employee Name -
        <span class="contentdisplay">{{ waybill.employeeName }}</span>
        Employee Mobile Number -
        <span class="contentdisplay"
          >{{ waybill.employeeCountryCode }} {{ waybill.employeePhoneNo }}</span
        >
        <!--Generated At - <span class="contentdisplay">{{generatedAt}} </span>-->
      </v-flex>
    </v-layout>
    <div id="waybillPageFooter" v-if="!isloading">
      <v-layout class="py-1">
        <v-flex xs3 class="text-align-left" style="font-size: 11px">
          <div>Office-M15,</div>
          <div>The Curve Building, Sheikh Zayed Road,</div>
          <div>Dubai, UAE</div>
          <br />
          <div class="text-align-left">
            <div>مكتب 15م، بناية المنحني</div>
            <div>طريق الشيخ زايد ، دبي،</div>
            <div>العربية المتحدة</div>
          </div>
        </v-flex>
        <v-flex xs6 class="text-align-center">
          <div style="margin-top: 20px">
            <a
              style="font-size: 20px"
              class="web-link"
              target="_blank"
              href="https://www.trukkin.com"
              >www.trukkin.com</a
            >
          </div>
        </v-flex>
        <v-flex xs3 style="font-size: 11px; text-align: right">
          <div>
            <span style>Medad Building, Office 206,</span>
          </div>
          <div>
            <span style>East Ring Road, Exit 14-15, Riyadh</span>
          </div>
          <br />
          <div>
            مكتب 206، بناية مداد ، الطريق الدائري الشرقي مخرج 14، الرياض
          </div>
        </v-flex>
      </v-layout>
    </div>
    <div class="print-link" v-if="!isloading">
      <v-btn @click.prevent="print" color="orange darken-2" class="white--text"
        >Print Waybill</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import WaybillDetailContent from "./WaybillDetailContent";
import { waybillSectionApiUrl } from "@/constants/api-urls.js";
import VueBarcode from "vue-barcode";
import TinyURL from "tinyurl";
import { StorageKeys } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
export default {
  components: {
    WaybillDetailContent,
    VueBarcode,
  },
  created() {
    EventBus.$emit("in-waybill");
    this.$eventBus.$emit("hide-navbar");
    const { id, type } = this.$route.params;
    if (!id) {
      alert("Invalid Page URL Parameters. Please contact administrator");
    }
    this.fetch(id);
    this.setCountry(type);
  },
  watch: {
    barcodeValue() {},
  },
  data() {
    return {
      barcodeValue: null,
      waybill: {
        date: null,
        trukkinSNo: null,
        barcodeValue: "",
        transporterPolicyNo: null,
        customerRefNo: null,
        consignee: null,
        consigneeAddress: null,
        consignor: null,
        consignorAddress: null,
        consignorPhone: null,
        idNo: null,
        driverName: null,
        driverContactNo: null,
        plateNo: null,
        truckNo: null,
        assignmentId: null,
        transporterName: null,
        operationContactNo: null,
        additionalNotes: null,
        additionalDetails: null,
        jobId: null,
        employeeName: null,
        employeeID: null,
        employeePhoneNo: null,
        employeeCountryCode: null,
        generatedAt: null,
      },
      isloading: false,
      generatedAt: null,
      url: "",
    };
  },
  methods: {
    shortUrl() {
      var TinyURL = require("tinyurl");

      TinyURL.shorten(window.location.href, (res, err) => {
        if (err) this.barcodeValue = res;
      });
    },
    setCountry(type) {
      this.country = type;
    },
    fetch(id) {
      this.shortUrl();
      this.isloading = true;
      if (localStorage.getItem(StorageKeys.SessionKey)) {
        this.url = waybillSectionApiUrl.waybillDetail;
      }
      if (!localStorage.getItem(StorageKeys.SessionKey)) {
        this.url = waybillSectionApiUrl.waybillDetailWithoutAuth;
      }
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignId: id.toString(),
      };
      this.axios.post(this.constants.apiUrl + this.url, body, config).then(
        (response) => {
          this.isloading = false;
          const { data = null } = response.data;
          if (data) {
            this.waybill = data;
            var date = new Date(this.waybill.generatedAt);
            this.generatedAt = date.toLocaleString();
            return;
          } else {
            alert("Failed to build waybill");
            // window.close();
          }
        },
        (error) => {
          this.isloading = false;
          const message = this.$eventBus.parse(error);
          alert(message);
          // window.close();
        }
      );
    },
    print() {
      window.print();
    },
    timestamp(epoch) {
      return moment.utc(epoch).format("DD/MM/YYYY");
    },
  },
};
</script>
<style scoped></style>

<style lang="scss">
.bar-code-style {
  display: inline-block !important;
  width: 100% !important;
  /* height: 69px; */
  text-align: end !important;
}
.bar-code-style svg {
  position: inherit;
  right: 0 !important;
  margin: 0 auto;
}

#waybillPage {
  //border: 2px solid #00000021;
  top: 10px;
  left: 10%;
  // min-height: 100vh;
  height: 100%;
  width: 100%;
  // margin: auto;
  font-size: 14px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;

  .miscbox {
    border: 1px solid #00000021 !important;
    padding: 0px 0px;
  }
}
.text-left {
  text-align: left;
}

@page {
  margin: 0cm;
}
#waybillcontent {
  color: #f57c00;

  top: 10px;
  left: 10%;

  height: 100%;
  width: 100%;

  font-size: 14px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

#waybillPageFooter {
  width: 100%;
  // margin: auto;
  min-width: 80vw;
}
#waybillPageFooter .layout {
  border-top: 5px solid #f57c00 !important;
  padding-top: 10px !important;
  color: #f57c00;
}

.web-link {
  font-size: 20px;
  color: #f57c00;
  border: 2px solid #f57c00;
  padding: 10px 20px;
  text-decoration: none;
}
.contentdisplay {
  font-weight: bolder;
  color: black;
  padding-right: 5px;
}
.pdd {
  padding-bottom: 5px;
}

.print-link {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.text-center {
  text-align: center !important;
}
.bold {
  font-weight: bold !important;
}
.f-14 .value {
  font-size: 14px;
}

@media print {
  .print-link {
    display: none;
  }
  body {
    background-color: #ffffff;
    background-image: none;
    color: #000000;
  }
  #ad {
    display: none;
  }
  #leftbar {
    display: none;
  }
  #contentarea {
    width: 100%;
  }
  .miscbox {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2px 0px;
  }
}
</style>
