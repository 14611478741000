import Vue from "vue";
import VueCountdownTimer from "vuejs-countdown-timer";
import VueTimepicker from "vue2-timepicker";
import "babel-polyfill";
import App from "./App.vue";
import router from "./router";
import Router from "vue-router";
// import store from "./store";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import axios from "axios";
import VueAxios from "vue-axios";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import removeLocalstorageMixin from "@/mixins/removeLocalstorageMixin";
Vue.use(DateRangePickerPlugin);
import { StorageKeys } from "@/constants/constants.js";
import Swal from "sweetalert2";
import { EventBus } from "./event-bus.js";
import * as VueGoogleMaps from "vue2-google-maps";
import GAuth from "vue-google-oauth2";
import VuetifyDaterangePicker from "vuetify-daterange-picker";
import "vuetify-daterange-picker/dist/vuetify-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import firebase from "firebase/app";
import "firebase/messaging";
import VueChatScroll from "vue-chat-scroll";
import VueMixpanel from "vue-mixpanel";
import { mixin } from "lodash";

Vue.use(VueMixpanel, {
  token: "f0a451b8b9ace868731bb230081522eb",
  config: {
    debug: true,
  },
});

const sessionKey = localStorage.getItem(StorageKeys.SessionKey);
if (sessionKey) {
  axios.defaults.headers.common["token"] = sessionKey;
}

if (window.location.protocol !== "https:") {
  // window.location.href = ''
}

window.addEventListener("offline", () => {
  if (!navigator.onLine) {
    Swal.fire({
      title: "Oops!",
      text: "Your internet connection seems to be offline.Please try again.",
      type: "error",
      allowOutsideClick: false,
      confirmButtonColor: "orange",
      confirmButtonText: "Okay",
    }).then((result) => {
      if (result.value) {
      }
    });
  }
});

Vue.use(VueAxios, axios);
Vue.use(Vuetify);
Vue.use(VuetifyDaterangePicker);
Vue.use(DateRangePicker);

Vue.use(VueCountdownTimer);
Vue.use(VueTimepicker);
Vue.use(VueChatScroll);
Vue.use(Router);
const gauthOption = {
  clientId:
    "37074936559-004l14inpgohusb37iij3h61p440s2lo.apps.googleusercontent.com",
  scope: "profile email ",
  prompt: "select_account",
  hd: "trukkin.com",
};
Vue.use(GAuth, gauthOption);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8",
    language: "en",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
    installComponents: true,
  },
});

firebase.initializeApp({
  apiKey: "AIzaSyAoVX_vxGxOJWsnVqyIYSqw9jcxfmd7_b4",
  authDomain: "omstrukkin.firebaseapp.com",
  databaseURL: "https://omstrukkin.firebaseio.com",
  projectId: "omstrukkin",
  storageBucket: "omstrukkin.appspot.com",
  messagingSenderId: "937073590191",
  appId: "1:937073590191:web:f9ee9f8f35ad5b80e9395a",
  measurementId: "G-2CCRSZK49W",
});
if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  messaging
    .getToken()
    .then(function (data) {
      if (data) {
        localStorage.setItem("webToken", data);
      }
    })
    .catch(function (err) {});

  messaging.onMessage(function (payload) {});
}

// router.onError((error) => {
//   if (/loading chunk \d* failed./i.test(error.message)) {
//     window.location.reload();
//   }
// });
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (
      error.response.status === 401 &&
      error.response.data.message != "Invalid credentials!"
    ) {
      let SwalValue = await Swal.fire({
        title: "Oops!",
        text: "Seems like your session is expired. Please login again.",
        type: "error",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: "orange",
        confirmButtonText: "Okay",
      });

      if (SwalValue) {
        let API_END_POINT = process.env.VUE_APP_API_URL;
        delete axios.defaults.headers.common["authorization"];
        let url = "/dmsAdmin/webViewIntimation";
        let email = window.location.href.split("=");

        let body = {};
        if (email && email.length && email[1]) {
          body.email = decodeURIComponent(email[1]);
        } else if (localStorage.getItem("isWebView")) {
          body.email = localStorage.getItem("email");
        }

        let response = await axios
          .post(API_END_POINT + url, body)
          .then((result) => {
            if (result.status == 200) {
              localStorage.removeItem("isWebView");
            }
          });
        //  localStorage.setItem("first-load", true);
        router.replace("/auth");
        //Comman local storage
        localStorage.removeItem("webToken");
        localStorage.removeItem("activeMenu");
        localStorage.removeItem("permissions");
        localStorage.removeItem("isWebView");
        localStorage.removeItem("userId");
        localStorage.removeItem("user");
        localStorage.removeItem("role");
        localStorage.removeItem("cust-status");
        localStorage.removeItem("user_Id");
        localStorage.removeItem("email");
        localStorage.removeItem("unreadCount");
        localStorage.removeItem("workingCountries");
        localStorage.removeItem("changeTabListing");
        //Jobs section local storage
        localStorage.removeItem("paginationUrlJob");
        localStorage.removeItem("searchJob");
        localStorage.removeItem("searchJobDriver");
        localStorage.removeItem("searchJobType");
        localStorage.removeItem("searchJobDriverType");
        localStorage.removeItem("subRealBook");
        localStorage.removeItem("subJobType");
        localStorage.removeItem("filterType");
        localStorage.removeItem("subGeoType");
        localStorage.removeItem("subJobMode");
        localStorage.removeItem("jobStatus");
        localStorage.removeItem("jobTabStatus");
        localStorage.removeItem("JobstartdateFilter");
        localStorage.removeItem("JobenddateFilter");
        //Procurement section local storage
        localStorage.removeItem("subJobTypePro");
        localStorage.removeItem("ProtartdateFilter");
        localStorage.removeItem("ProenddateFilter");
        localStorage.removeItem("searchProcurement");
        localStorage.removeItem("searchProcurementType");
        localStorage.removeItem("searchProcurementDriver");
        localStorage.removeItem("searchProcurementDriverType");
        localStorage.removeItem("paginationUrlProcure");
        //Driver section local storage
        localStorage.removeItem("searchDriverPreferredLocation");
        localStorage.removeItem("searchDriverStatus");
        localStorage.removeItem("searchDriverType");
        localStorage.removeItem("searchDrivertext");
        localStorage.removeItem("DriverJobType");
        localStorage.removeItem("subTruckType");
        localStorage.removeItem("driverVerificationStatus");
        localStorage.removeItem("payloadDriver");
        //Transporter Section local storage
        localStorage.getItem("searchTransporter");
        //Assignment Section local storage
        localStorage.removeItem("paginationUrlAssignment");
        localStorage.removeItem("TabStatusAssign");
        //Advance Section local Storage
        localStorage.removeItem("paginationUrlAdvance");
      }
    }
    if (
      error.response.status === 403 &&
      error.response.data.responseType == "BLOCKED_BY_ADMIN"
    ) {
      Swal.fire({
        title: "Oops!",
        text: "Blocked By Admin. Please contact Adminstrator.",
        type: "error",
        confirmButtonColor: "orange",
        confirmButtonText: "Okay",
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem(StorageKeys.SessionKey);
          window.location.reload();
          //Comman local storage
          localStorage.removeItem("webToken");
          localStorage.removeItem("activeMenu");
          localStorage.removeItem("permissions");
          localStorage.removeItem("TabStatusAssign");
          localStorage.removeItem("userId");
          localStorage.removeItem("user");
          localStorage.removeItem("role");
          localStorage.removeItem("cust-status");
          localStorage.removeItem("user_Id");
          localStorage.removeItem("email");
          localStorage.removeItem("unreadCount");
          localStorage.removeItem("workingCountries");
          localStorage.removeItem("changeTabListing");
          //Jobs section local storage
          localStorage.removeItem("paginationUrlJob");
          localStorage.removeItem("searchJob");
          localStorage.removeItem("searchJobDriver");
          localStorage.removeItem("searchJobType");
          localStorage.removeItem("searchJobDriverType");
          localStorage.removeItem("subRealBook");
          localStorage.removeItem("subJobType");
          localStorage.removeItem("filterType");
          localStorage.removeItem("subGeoType");
          localStorage.removeItem("subJobMode");
          localStorage.removeItem("jobStatus");
          localStorage.removeItem("jobTabStatus");
          localStorage.removeItem("JobstartdateFilter");
          localStorage.removeItem("JobenddateFilter");
          //Procurement section local storage
          localStorage.removeItem("subJobTypePro");
          localStorage.removeItem("ProtartdateFilter");
          localStorage.removeItem("ProenddateFilter");
          localStorage.removeItem("searchProcurement");
          localStorage.removeItem("searchProcurementType");
          localStorage.removeItem("searchProcurementDriver");
          localStorage.removeItem("searchProcurementDriverType");
          localStorage.removeItem("paginationUrlProcure");
          //Driver section local storage
          localStorage.removeItem("searchDriverPreferredLocation");
          localStorage.removeItem("searchDriverStatus");
          localStorage.removeItem("searchDriverType");
          localStorage.removeItem("searchDrivertext");
          localStorage.removeItem("DriverJobType");
          localStorage.removeItem("subTruckType");
          localStorage.removeItem("driverVerificationStatus");
          localStorage.removeItem("payloadDriver");
          //Transporter Section local storage
          localStorage.getItem("searchTransporter");
          //Assignment Section local storage
          localStorage.removeItem("paginationUrlAssignment");
          localStorage.removeItem("TabStatusAssign");
          //Advance Section local Storage
          localStorage.removeItem("paginationUrlAdvance");
        }
      });
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
Vue.prototype.constants = {
  // ------------- LIVE ENVIRONMENTS
  //serverUrl: "https://dms-api.trukkin.com", //----- Enter server url here for APIs
  //apiUrl: "http://52.8.197.168:7000"

  // ------------- TEST ENVIRONMENTS
  serverUrl: process.env.VUE_APP_SERVER_URL, //----- Enter server url here for APIs
  apiUrl: process.env.VUE_APP_API_URL,
  rbacUrl: process.env.VUE_APP_RBAC_URL,
  adminAPI: process.env.VUE_APP_API_URL,
  mappingUrl: process.env.VUE_APP_MAPPING_URL,
  mappingUrlV2: process.env.VUE_APP_MAPPING_URL_V2,
  mixpanelUrl: process.env.VUE_MIX_PANEL_URL,
  webAppUrl: process.env.VUE_APP_WEB_URL,
};
Vue.prototype.$eventBus = new Vue({
  methods: {
    parse(
      { response = null },
      defaultMsg = "Something went wrong.Try again later."
    ) {
      let error = defaultMsg;
      if (response) {
        const { data = null, message = null } = response.data;
        if (data && data.message) {
          error = data.message;
        } else if (message) {
          error = message;
        }
      }
      return error;
    },
    created: function () {
      Eventbus.$on("User-Id", function (e) {});
    },
  },
});
Vue.prototype.checkOpertaionPermission = async function (operationName) {
  let userPermissions = JSON.parse(
    localStorage.getItem("permissions")
  ).permissions;
  let operationId = "";
  let v = "";
  userPermissions.filter((x) => {
    if (x.operationName == operationName) {
      operationId = x._id;
      return;
    }
  });
  if (operationId) {
    const { serverUrl, rbacUrl, apiUrl } = this.constants;
    v = this.$http
      .post(`${rbacUrl}/checkOperationPermission`, {
        userId: localStorage.getItem("userId"),
        operation: operationId,
      })
      .then((res) => {
        if (!res.data.data) {
          this.loading = false;
        }
        return operationName;
      })
      .catch((e) => {
        if (e) {
          this.$eventBus.$emit("stop-load");
          if (!navigator.onLine) {
            Swal.fire({
              title: "Oops!",
              text: "Your internet connection seems to be offline.Please try again.",
              type: "error",
              allowOutsideClick: false,
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              if (result.value) {
              }
            });
          } else {
            Swal.fire({
              title: "Oops!",
              text: "You don't have permission",
              type: "error",
              allowOutsideClick: false,
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              if (result.value) {
              }
            });
          }
        }
      });
    return v;
  }
};
Vue.config.productionTip = false;

Vue.directive("permissions", {
  componentUpdated(el, permission) {
    if (permission.value) {
      if (
        localStorage.getItem("permissions") &&
        localStorage.getItem(StorageKeys.SessionKey)
      ) {
        let userPermissions = JSON.parse(
          localStorage.getItem("permissions")
        ).permissions;
        if (!userPermissions) {
          el.style.display = "none";
        } else {
          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          userPermissions = userPermissions.map((x) => x.operationName);
          roles = roles.map((x) => x.role.roleName.toLowerCase());
          if (permission.value.includes("/")) {
            let isRole = permission.value.split("/")[1];
            let role = permission.value.split("/")[0];
            if (isRole == "true") {
              if (roles.indexOf(role) == -1) {
                el.style.display = "none";
              }
            }
          } else {
            if (userPermissions.indexOf(permission.value) == -1) {
              el.style.display = "none";
            }
          }
        }
      }
    }
  },

  inserted(el, permission) {
    if (permission.value) {
      if (
        localStorage.getItem("permissions") &&
        localStorage.getItem(StorageKeys.SessionKey)
      ) {
        let userPermissions = JSON.parse(
          localStorage.getItem("permissions")
        ).permissions;
        if (!userPermissions) {
          el.style.display = "none";
        } else {
          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          userPermissions = userPermissions.map((x) => x.operationName);
          roles = roles.map((x) => x.role.roleName.toLowerCase());
          if (permission.value.includes("/")) {
            let isRole = permission.value.split("/")[1];
            let role = permission.value.split("/")[0];
            if (isRole == "true") {
              if (roles.indexOf(role) == -1) {
                el.style.display = "none";
              }
            }
          } else {
            if (userPermissions.indexOf(permission.value) == -1) {
              el.style.display = "none";
            }
          }
        }
      }
    }
  },
});

Vue.directive("permissions1", {
  componentUpdated(el, permission) {
    if (permission.value) {
      if (permission.value)
        if (
          localStorage.getItem("permissions") &&
          localStorage.getItem(StorageKeys.SessionKey)
        ) {
          let userPermissions = JSON.parse(
            localStorage.getItem("permissions")
          ).permissions;
          if (!userPermissions) {
            el.style.display = "none";
          } else {
            let roles = JSON.parse(localStorage.getItem("permissions")).roles;
            userPermissions = userPermissions.map((x) => x.operationName);
            roles = roles.map((x) => x.role.roleName.toLowerCase());
            let p1 = userPermissions.indexOf(permission.value[0]);
            let p2 = userPermissions.indexOf(permission.value[1]);
            if (p1 == -1 && p2 == -1) {
              el.style.display = "none";
              Swal.fire({
                title: "Oops!",
                text: "You don't  have permission ",
                type: "error",
                allowOutsideClick: false,
                confirmButtonColor: "orange",
                allowEscapeKey: false,
                confirmButtonText: "Okay",
              }).then((result) => {
                localStorage.setItem("first-load", true);
                router.replace("/");
              });
            }
            /* if (permission.value.includes("/")) {
               let isRole = permission.value.split("/")[1];
               let role = permission.value.split("/")[0];
              
               if (isRole == "true") {
                 if (roles.indexOf(role) == -1) {
                   el.style.display = "none";
                 }
               }
             } else {
               if (userPermissions.indexOf(permission.value) == -1) {
                 el.style.display = "none";
               }
             }*/
          }
        }
    }
  },
  inserted(el, permission) {
    if (permission.value) {
      if (
        localStorage.getItem("permissions") &&
        localStorage.getItem(StorageKeys.SessionKey)
      ) {
        let userPermissions = JSON.parse(
          localStorage.getItem("permissions")
        ).permissions;
        if (!userPermissions) {
          el.style.display = "none";
        } else {
          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          userPermissions = userPermissions.map((x) => x.operationName);
          roles = roles.map((x) => x.role.roleName.toLowerCase());
          let p1 = userPermissions.indexOf(permission.value[0]);
          let p2 = userPermissions.indexOf(permission.value[1]);
          if (p1 == -1 && p2 == -1) {
            el.style.display = "none";
          }
          /*if (permission.value.includes("/")) {
            debugger
            let isRole = permission.value.split("/")[1];
            let role = permission.value.split("/")[0];
            if (isRole == "true") {
              if (roles.indexOf(role) == -1) {
                el.style.display = "none";
              }
            }
          } else {
            if (userPermissions.indexOf(permission.value) == -1) {
              el.style.display = "none";
            }
          }*/
        }
      }
    }
  },
});
Vue.prototype.webViewLogout = function (id, action = {}) {
  let API_END_POINT = process.env.VUE_APP_API_URL;
  delete axios.defaults.headers.common["authorization"];
  let url = "/dmsAdmin/webViewIntimation";
  axios.post(API_END_POINT + url, {});
};
Vue.prototype.track = function (id, action = {}) {
  this.$mixpanel.track(id, action);
};
Vue.prototype.assigDynamicStatus = function (id, action = {}) {
  delete this.axios.defaults.headers.common["token"];
  let token = localStorage.getItem(StorageKeys.SessionKey);
  let config = {
    headers: {
      authorization: `${token}`,
    },
  };
  this.t = config.headers.authorization;

  const url = `${this.constants.apiUrl}/dmsAdmin/searchListArray`;
  this.axios.get(url).then((response) => {
    this.statusList = response.data.data.assignStatus;
  });
};

Vue.prototype.assignmentStatus = function (id, action = {}) {
  switch (id) {
    case 1:
      return "Driver Not Assigned";
    case 2:
      return "Driver Assigned";
    case 3:
      return "Payment Pending";
    case 4:
      return "Payment Done";
    case 5:
      return "In-Transit";
    case 6:
      return "Shipment Offloaded & POD Awaited";
    case 7:
      return "Document Received";
    case 8:
      return "Driver Partially Assigned";
    case -1:
      return "Cancelled";
    default:
      return "Unknown";
  }
};
Vue.prototype.jobsStatus = function (id, action = {}) {
  switch (id) {
    case 1:
      return "Active";
    case 2:
      return "Complete";
    case 3:
      return "Customer Rate Accepted";
    case 4:
      return "Finding Drivers";
    case 5:
      return "Driver Assigned";
    case 6:
      return "In-Transit";
    case 7:
      return "Shipment Offloaded & POD Awaited";
    case 8:
      return "Payment Pending";
    case 9:
      return "Payment Done";
    case 10:
      return "Low Credit Limit";
    case 11:
      return "Document Received";
    case 12:
      return "Driver Partially Assigned";

    case -1:
      return "Job Cancelled";
    default:
      return "Unknown";
  }
};

Vue.prototype.setPeople = function (data = {}) {
  this.$mixpanel.people.set(data);
};
Vue.prototype.alias = function (email) {
  this.$mixpanel.alias("$distinct_id", email);
};
Vue.prototype.identify = function (id) {
  this.$mixpanel.identify(localStorage.getItem("email"));
};
Vue.prototype.resetId = function () {
  this.$mixpanel.reset();
};

new Vue({
  router,
  mixins: [removeLocalstorageMixin],
  render: (h) => h(App),
}).$mount("#app");
