<template>
  <v-container fluid style="background: #fff !important;margin-top:-60px;">
    <div v-if="isloading" class="text-align-center">
      Generating Waybill/TCN...
    </div>
    <div class="print-link" v-if="!isloading">
      <v-btn @click.prevent="print" color="orange darken-2" class="white--text"
        >Print</v-btn
      >
    </div>
    <div class="bill-A4 containers" v-if="!isloading">
      <div id="waybillPage">
        <!--head-->
        <v-layout
          row
          wrap
          d-flex
          justify-content-between
          mt-3
          style="font-size: 13px;margin: 15px 0px;margin-top:0!important;color: #828282;align-items:center;"
          class="d-flex justify-content-between align-items-center"
        >
          <v-flex class="logo text-left">
            <img
              v-if="country == 'PAK'"
              src="../../assets/logo_pak.jpg"
              height="60px"
            />
            <img
              v-if="country == 'UAE'"
              src="../../assets/logo.jpg"
              height="60px"
            />
            <img
              v-if="country == 'KSA'"
              src="../../assets/logo-waybill.png"
              height="60px"
            />
          </v-flex>
          <v-flex class="date" style="align-items:center;">
            <div>
              <div style="font-size: 11px;color: #6c6c6c;font-weight:500;">
                DATE تاريخ
              </div>
              <div
                style="font-size: 16px;color: #000000;font-weight:bold;margin-top:8px;"
              >
                {{ timestamp(waybill.pickupDate) }}
              </div>
            </div>
          </v-flex>
          <v-flex class="date" style="align-items:center;">
            <div>
              <div style="font-size: 12px;color: #6c6c6c;font-weight:500;">
                WAYBILL/TCN NO. رقم بوليصة الناقل
              </div>
              <div
                style="font-size: 16px;color: #000000;font-weight:bold;margin-top:8px;"
              >
                {{ waybill.assignmentId }}
              </div>
            </div>
          </v-flex>

          <v-flex
            v-if="waybill.customerId == 'CUS1'"
            style="align-items:center;"
          >
            <VueBarcode
              v-bind:value="waybill.assignmentId"
              class="bar-code-style"
              :displayValue="'false'"
              width="1"
              height="50"
            >
              Show this if the rendering fails.
            </VueBarcode>
          </v-flex>

          <v-flex
            v-if="waybill.wayBillSequenceNo"
            class="date"
            style="align-items:center;"
          >
            <div>
              <div
                style="font-size: 16px;color: #000000;font-weight:bold;margin-top:8px;"
              >
                {{ waybill.wayBillSequenceNo }}
              </div>
            </div>
          </v-flex>
          <v-flex v-if="waybill.customerId != 'CUS1'" class="text-right">
            <QrcodeVue :value="waybbillObjStringify()" :size="size" level="H" />
            <!-- <QrcodeVue v-if="waybill.wayBillSequenceNo" :value="value.href" :size="size" level="H" /> -->
            <!-- <img v-if="!waybill.wayBillSequenceNo" src="../../assets/qrcodeimage.png" height="60px" /> -->
          </v-flex>
        </v-layout>
        <!--head-->
        <!-- sub heading -->
        <v-layout
          row
          wrap
          d-flex
          justify-content-between
          mt-3
          style="font-size: 13px;margin: 15px 0px;margin-top:0!important;color: #828282;align-items:center;"
          class="d-flex justify-content-between align-items-center"
          v-if="waybill.customerId == 'CUS1'"
        >
          <v-flex>
            <div style="font-size: 16px;color: #000000;font-weight:bold;">
              Shipment No. -
            </div>
          </v-flex>
        </v-layout>
        <!-- sub heading ends here -->
        <!--job-details-->
        <div
          class="details-head"
          style="background: #f7f7f7; padding:10px; border-radius: 6px; margin-top: 15px;"
        >
          <div class>
            <label
              style="font-size: 15px;color: #6c6c6c;font-weight:900;  margin-bottom: 0px;"
            >
              JOB ID : {{ waybill.jobId || "N.A." }} <br />معرف الوظيفة
            </label>
          </div>
          <div class>
            <label
              style="font-size: 11px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px;"
            >
              CUSTOMER ID : {{ waybill.customerId }} <br />معرف العميل
            </label>
          </div>
          <div class>
            <label
              style="font-size: 11px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px;"
            >
              LOADING POINT : {{ waybill.consignorAddress }} <br />نقطة التحميل
            </label>
          </div>
          <div class>
            <label
              style="font-size: 11px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px;"
            >
              OFFLOADING POINT : {{ waybill.consigneeAddress }} <br />نقطة
              التنزيل
            </label>
          </div>
        </div>
        <!--job-details-->
        <!--job-Consigner-->
        <v-container grid-list-md d-flex fluid>
          <v-layout
            row
            wrap
            d-flex
            justify-content-between
            mt-3
            class="row mt-3 d-flex justify-content-between"
          >
            <v-flex xs4 class="col-md-4">
              <div class="heading-con">
                Consignor:
                <span style="font-weight: 500;">المرسل</span>
              </div>
              <div class="con-box">
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    NAME :
                    <br />
                    <span>اسم</span>
                  </label>
                  <p>{{ waybill.consignorName }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    COUNTRY :
                    <br />
                    <span>البلد</span>
                  </label>
                  <p>{{ waybill.consignorCountry }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    ADDRESS :
                    <br />
                    <span>العنوان</span>
                  </label>
                  <p>{{ waybill.consignorAddress }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    PHONE :
                    <br />
                    <span>رقم الهاتف</span>
                  </label>
                  <p></p>
                </div>
              </div>
            </v-flex>
            <v-flex v-if="waybill.customerId != 'CUS1'" xs4 class="col-md-4">
              <div class="heading-con">
                Consignee:
                <span style="font-weight: 500;">المرسل اليه</span>
              </div>
              <div class="con-box">
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    NAME :
                    <br />
                    <span>اسم</span>
                  </label>
                  <p>{{ waybill.consigneeName }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    COUNTRY :
                    <br />
                    <span>البلد</span>
                  </label>
                  <p>{{ waybill.consigneeCountry }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    ADDRESS :
                    <br />
                    <span>العنوان</span>
                  </label>
                  <p>{{ waybill.consigneeAddress }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    PHONE :
                    <br />
                    <span>رقم الهاتف</span>
                  </label>
                  <p>{{ waybill.consigneePhone }}</p>
                </div>
              </div>
            </v-flex>
            <v-flex v-if="waybill.customerId == 'CUS1'" xs8 class="col-md-8">
              <div class="heading-con">
                Consignee:
                <span style="font-weight: 500;">المرسل اليه</span>
              </div>
              <div class="con-box">
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    NAME :
                    <br />
                    <span>اسم</span>
                  </label>
                  <p>{{ waybill.consigneeName }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    COUNTRY :
                    <br />
                    <span>البلد</span>
                  </label>
                  <p>{{ waybill.consigneeCountry }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    ADDRESS :
                    <br />
                    <span>العنوان</span>
                  </label>
                  <p>{{ waybill.consigneeAddress }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    PHONE :
                    <br />
                    <span>رقم الهاتف</span>
                  </label>
                  <p>{{ waybill.consigneePhone }}</p>
                </div>
              </div>
            </v-flex>
            <v-flex v-if="waybill.customerId != 'CUS1'" xs4 class="col-md-4">
              <div class="heading-con">
                Receiver:
                <span style="font-weight: 500;">المستلم</span>
              </div>
              <div class="con-box receiver">
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    NAME :
                    <br />
                    <span>اسم</span>
                  </label>
                  <p></p>
                </div>
                <div class="mt-3 mt-100 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    COMPANY SEAL / SIGNATURE :
                    <br />
                    <span>ختم الشركة / التوقيع</span>
                  </strong>
                </div>
                <p></p>
                <div class="mt-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    Date :
                    <br />
                    <span>تاريخ</span>
                  </label>
                  <p></p>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <!--job-Consigner-->
        <!--job-Consigner-1-->
        <v-container grid-list-md d-flex fluid>
          <v-layout
            row
            wrap
            d-flex
            justify-content-between
            mt-3
            class="row mt-3 d-flex justify-content-between"
          >
            <v-flex
              v-if="waybill.customerId == 'CUS1'"
              xs12
              md4
              class="col-md-4"
            >
              <div class="heading-con">
                <img
                  src="../../assets/driver.jpg"
                  style="width: 40px;"
                />Driver:
                <span style="font-weight: 500;">السائق</span>
              </div>
              <div class="con-box h-160">
                <div class="d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    NAME :
                    <br />
                    <span>اسم</span>
                  </strong>
                  <p>{{ waybill.driverName }}</p>
                </div>
                <div class="mt-3 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    ID NO.:
                    <br />
                    <span>رقم المعرف</span>
                  </strong>
                  <p>{{ waybill.idNo }}</p>
                </div>
                <div class="mt-3 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    PHONE :
                    <br />
                    <span>رقم الهاتف</span>
                  </strong>
                  <p>{{ waybill.driverContactNo }}</p>
                </div>
              </div>
            </v-flex>
            <v-flex
              v-if="waybill.customerId != 'CUS1'"
              xs12
              md4
              class="col-md-4"
            >
              <div class="heading-con">
                <img src="../../assets/cargo.jpg" style="width: 40px;" />Cargo:
                <span style="font-weight: 500;">البضائع</span>
              </div>
              <div class="con-box h-160">
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    COMMODITY :
                    <br />
                    <span>السلع الاساسيه</span>
                  </label>
                  <p>{{ waybill.commodity }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    WEIGHT :
                    <br />
                    <span>الوزن</span>
                  </label>
                  <p></p>
                </div>
              </div>
            </v-flex>
            <v-flex xs12 md4 class="col-md-4">
              <div class="heading-con">
                <img src="../../assets/truck.jpg" style="width: 40px;" />Truck:
                <span style="font-weight: 500;">شاحنه</span>
              </div>
              <div class="con-box h-160">
                <div class="mb-3 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    TYPE :
                    <br />
                    <span>نوع</span>
                  </strong>
                  <p>{{ waybill.truckTypeName }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    PLATE NO. :
                    <br />
                    <span>لوحة السيارة</span>
                  </strong>
                  <p>{{ waybill.truckNo }}</p>
                </div>
                <div class="mb-3 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    GPS :
                    <br />
                    <span>تحديد المواقع</span>
                  </strong>
                  <p>{{ waybill.imeiNumber ? waybill.imeiNumber : "No" }}</p>
                </div>
              </div>
            </v-flex>
            <v-flex
              v-if="waybill.customerId != 'CUS1'"
              xs12
              md4
              class="col-md-4"
            >
              <div class="heading-con">
                <img
                  src="../../assets/driver.jpg"
                  style="width: 40px;"
                />Driver:
                <span style="font-weight: 500;">السائق</span>
              </div>
              <div class="con-box h-160">
                <div class="d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    NAME :
                    <br />
                    <span>اسم</span>
                  </strong>
                  <p>{{ waybill.driverName }}</p>
                </div>
                <div class="mt-3 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    ID NO.:
                    <br />
                    <span>رقم المعرف</span>
                  </strong>
                  <p>{{ waybill.idNo }}</p>
                </div>
                <div class="mt-3 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    PHONE :
                    <br />
                    <span>رقم الهاتف</span>
                  </strong>
                  <p>{{ waybill.driverContactNo }}</p>
                </div>
              </div>
            </v-flex>
            <v-flex v-if="waybill.customerId == 'CUS1'" xs4 class="col-md-4">
              <div class="heading-con">
                <img src="../../assets/received.png" style="width: 40px;" />
                Receiver:
                <span style="font-weight: 500;">المستلم</span>
              </div>
              <div class="con-box receiver h-160">
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    NAME :
                    <br />
                    <span>اسم</span>
                  </label>
                  <p></p>
                </div>
                <div class="mt-3 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    COMPANY SEAL / SIGNATURE :
                    <br />
                    <span>ختم الشركة / التوقيع</span>
                  </strong>
                </div>

                <div class="mt-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    Date :
                    <br />
                    <span>تاريخ</span>
                  </label>
                  <p></p>
                </div>
                <div class="mt-3 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    COMMENTS :
                    <br />
                    <span>تعليقات</span>
                  </strong>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <v-container grid-list-md d-flex fluid>
          <v-layout
            row
            wrap
            d-flex
            justify-content-between
            mt-3
            class="row mt-3 d-flex justify-content-between"
          >
            <v-flex
              v-if="waybill.customerId != 'CUS1'"
              xs4
              md4
              class="col-md-4"
            >
              <div class="invoice">
                <div class="mb-3 d-flex">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    INVOICE NO. : {{ waybill.invoiceNumber }}
                    <br />
                    <span>رقم الفاتورة</span>
                  </label>
                  <p></p>
                </div>
                <div class="mt-3 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    INVOICE VALUE : {{ waybill.invoiceValue }}
                    <br />
                    <span>قيمة الفاتورة</span>
                  </strong>
                  <p></p>
                </div>
              </div>
            </v-flex>
            <v-flex
              v-if="waybill.customerId != 'CUS1'"
              xs8
              md8
              class="col-md-8"
            >
              <div class="invoice">
                <div class="mb-3">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    ADDITIONAL NOTES : {{ waybill.additionalNotes }}
                    <br />
                    <span>ملاحظات إضافية</span>
                  </label>
                </div>
                <div v-if="waybill.wayBillSequenceNo" class="mt-3 d-flex">
                  <strong
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    TRANSPORTER POLICY ID :
                    <br />
                    <span>رقم بوليصة النقل</span>
                  </strong>
                  <p></p>
                </div>
                <!-- <div class="mb-3">
                  <label
                    style="font-size: 12px;color: #6c6c6c;font-weight:700;  margin-bottom: 0px; line-height: 12px;"
                  >
                    ADDITIONAL DETAILS :
                    <br />
                    <span>تفاصيل إضافية</span>
                  </label>
                  <p>{{waybill.additionalDetails}}</p>
                </div>-->
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <!--job-Consigner-1-->
        <!-- short description -->
        <v-container grid-list-md d-flex fluid>
          <v-layout
            row
            wrap
            d-flex
            justify-content-between
            mt-3
            class="row"
            style="font-size: 8px;margin: 15px 0px;color: #828282;margin-bottom:0;"
          >
            <v-flex md6 class="w-50">
              <div>
                <p style="margin-bottom:0;">
                  Hereafter signing this waybill I am not eligible to hold/block
                  the shipment of Trukkin for any reason
                </p>
              </div>
            </v-flex>
            <v-flex md6 style="text-align:right" class="w-50 text-right">
              <div>
                <p style="margin-bottom:0;">
                  بعد التوقيع على بوليصة الشحن هذه،لا يجوز لى حجز هذه الشحنة
                  الخاصة بشركة تركين لأي سبب من الاسباب
                </p>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <!-- short description -->
        <!--description-->
        <v-container grid-list-md d-flex fluid>
          <v-layout
            row
            wrap
            d-flex
            justify-content-between
            mt-3
            class="row"
            style="font-size: 8px;margin: 15px 0px;color: #828282;margin-bottom:0;"
          >
            <v-flex md6 class="w-50">
              <div>
                <p style="margin-bottom:0;">
                  Trukkin is a member of National Association of Freight and
                  Logistics (NAFL) and is bound by the Standards Trading
                  Conditions (STC, available at www.nafl.ae) to the extent
                  applicable.The Shipper agrees and guarantees it is the owner
                  of the goods or the sole agent of the shipped goods. Trukkin
                  has complete right to inspect the shipment. Any Damage or Loss
                  will be dealt as per Trukkin Terms and Conditions of Shipment
                  policy. All Charges such as agreed rent, detention charges,
                  border charges or storage charges are as per agreed contract.
                </p>
              </div>
            </v-flex>
            <v-flex md6 style="text-align:right" class="w-50 text-right">
              <div>
                <p style="margin-bottom:0;">
                  وافق العميل على أنه مالك الشحنة. شركة تراكين لديها لحق الكامل
                  لفحص الشحنة. سيتم التعامل مع أي ضرر أو خسارة وفقًا لشروط
                  الشركة. رسوم الإيجار أو الانتظار أو التخزين أو الرسوم الجمركية
                  حسب العقد
                </p>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <v-layout
          row
          wrap
          d-flex
          justify-content-between
          mt-3
          class="contact"
          style="font-size: 13px;margin-top: 10px;color: #828282;"
        >
          <v-flex xs12 md4 justify-content-center class="col-md-6 text-center">
            <h3 style="font-size: 14px; color: #000;">
              <span>
                <img src="../../assets/call.jpg" style="width: 20px;" />
                &nbsp;UAE </span
              >800 - TRUKKIN
            </h3>
            <p
              style="font-size: 14px ;color: #000; font-weight: 500;margin-bottom:0;"
            >
              <img src="../../assets/mail.jpg" style="width: 20px;" />
              operations@trukkin.com
            </p>
          </v-flex>
          <v-flex xs12 md4 justify-content-center class="col-md-6 text-center">
            <h3 style="font-size: 14px; color: #000;">
              <span>
                <img
                  src="../../assets/call.jpg"
                  style="width: 20px;"
                />&nbsp;KSA </span
              >9200 - 04275
            </h3>
            <p
              style="font-size: 14px;color: #000;font-weight: 500;margin-bottom:0;"
            >
              <img src="../../assets/web.jpg" style="width: 20px" />
              www.trukkin.com
            </p>
          </v-flex>
          <v-flex xs12 md4 justify-content-center class="col-md-6 text-center">
            <h3 style="font-size: 14px; color: #000;">
              <span>
                <img
                  src="../../assets/call.jpg"
                  style="width: 20px;"
                />&nbsp;PAK </span
              >0311 TRUKKIN
            </h3>
            <p
              style="font-size: 14px;color: #000;font-weight: 500;margin-bottom:0;"
            >
              <img src="../../assets/mail.jpg" style="width:20px" />
              contact@trukkin.com
            </p>
          </v-flex>
        </v-layout>
        <!--contact-->
        <!--footer-->
        <v-layout
          row
          wrap
          d-flex
          justify-content-between
          mt-3
          class="row"
          style="margin-top: 15px;"
        >
          <v-flex xs12 md4 class="col-md-4">
            <label style="color: #000; font-weight: bold; font-size: 14px;"
              >We are now serving :</label
            >
            <br />
            <span
              style="color: #6c6c6c;font-weight: 500;word-spacing: 10px;font-size: 9px;"
              >JORDAN EGYPT OMAN LEBANON IRAQ UAE <br />KSA KUWAIT BAHRAIN YEMEN
              SYRIA PAKISTAN</span
            >
          </v-flex>
          <v-flex xs12 md4 class="col-md-4 " style="text-align:center;">
            <!-- <img src="../../assets/scan-here.jpg" style="margin-right:-4px;" height="70px" /> -->
            <!-- <img src="../../assets/qrcodeimage.png" height="50px" /> -->
            <QrcodeVue :value="waybbillObjStringify()" :size="size" level="H" />
          </v-flex>

          <v-flex xs12 md4 class="col-md-4 text-right">
            <label style="color: #000; font-weight: bold; font-size: 14px;"
              >Waybill/TCN generated by</label
            >
            <p style="color: #6c6c6c;font-weight: 500;font-size: 10px;">
              {{ waybill.employeeName }}
              {{ waybill.employeeID ? "(" + waybill.employeeID + ")" : "" }} |
              {{ getCurrentDate() }}
              <br />
              UAE: +971 - 563681471 | KSA: +966 - 567231193
            </p>
          </v-flex>
        </v-layout>
        <!--footer-->
      </div>
    </div>

    <div class="print-link" v-if="!isloading">
      <v-btn @click.prevent="print" color="orange darken-2" class="white--text"
        >Print</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import VueBarcode from "vue-barcode";
import QrcodeVue from "qrcode.vue";
import WaybillDetailContent from "./WaybillDetailContent";
import { StorageKeys, TruckTypes } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
export default {
  components: {
    WaybillDetailContent,
    QrcodeVue,
    VueBarcode,
  },
  created() {
    this.trucks = TruckTypes;
    EventBus.$emit("in-waybill");
    this.$eventBus.$emit("hide-navbar");
    const { id, type } = this.$route.params;
    this.country = type;
    if (!id) {
      alert("Invalid Page URL Parameters. Please contact administrator");
    }
    this.fetch(id);
    this.setCountry(type);
  },
  data() {
    return {
      country: null,
      value: window.location,
      size: 70,
      size1: 20,
      trucks: [],
      waybill: {
        date: null,
        wayBillSequenceNo: null,
        trukkinSNo: null,
        transporterPolicyNo: null,
        customerRefNo: null,
        consignee: null,
        consigneeAddress: null,
        consignor: null,
        consignorAddress: null,
        consignorPhone: null,
        idNo: null,
        driverName: null,
        driverContactNo: null,
        plateNo: null,
        truckNo: null,
        assignmentId: null,
        transporterName: null,
        operationContactNo: null,
        additionalNotes: null,
        additionalDetails: null,
        jobId: null,
        employeeName: null,
        employeeID: null,
        employeePhoneNo: null,
        employeeCountryCode: null,
        generatedAt: null,
        invoiceNumber: null,
        invoiceValue: null,
      },
      isloading: false,
      generatedAt: null,
      url: "",
    };
  },
  methods: {
    waybbillObjStringify() {
      let w = { ...this.waybill };
      let x = {
        assignmentId: w.assignmentId,
        workingCountry: this.country,
      };
      return JSON.stringify(x);
    },
    getCurrentDate() {
      let x = new Date();
      return moment(x).format("YYYY-MM-DD hh:mm a");
    },
    setCountry(type) {
      this.country = type;
    },
    fetch(id) {
      this.isloading = true;
      if (localStorage.getItem(StorageKeys.SessionKey)) {
        this.url = "/dmsAdmin/waybill";
      }
      if (!localStorage.getItem(StorageKeys.SessionKey)) {
        this.url = "/dmsAdmin/waybillWithoutAuth";
      }
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignId: id.toString(),
      };
      this.axios.post(this.constants.apiUrl + this.url, body, config).then(
        (response) => {
          this.isloading = false;
          const { data = null } = response.data;
          if (data) {
            this.waybill = data;
            var x = this.trucks.filter((v) => {
              if (v.value === this.waybill.truckType.toString()) return v;
            });
            this.waybill["truckTypeName"] = x[0].text;
            var date = new Date(this.waybill.generatedAt);
            this.generatedAt = date.toLocaleString();
            return;
          } else {
            alert("Failed to build waybill");
          }
        },
        (error) => {
          this.isloading = false;
          const message = this.$eventBus.parse(error);
          alert(message);
        }
      );
    },
    print() {
      window.print();
    },
    timestamp(epoch) {
      return moment.utc(epoch).format("DD/MM/YYYY");
    },
  },
};
</script>
<style lang="scss">
@page {
  margin: 0cm;
}
.mt-100 {
  margin-bottom: 80px !important;
}
.bill-A4 {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #333;
  padding: 15px;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 0;
}
.h-160 {
  height: 170px !important;
}
.container.grid-list-md .layout:only-child {
  margin-top: 10px !important;
}
.w-50 {
  width: 100%;
  float: left;
}
p {
  margin: 0;
}
h3 > span > img {
  position: relative !important;
  float: left;
}
p > img {
  position: relative;
  top: 7px;
}
.print-link {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.date {
  line-height: 12px;
}
.heading-con {
  font-size: 18px;
  font-weight: bold;
}
.heading-con span {
  font-weight: lighter !important;
  color: #6c6c6c;
  font-size: 17px;
}
.heading-con img {
  position: relative;
  top: 10px;
}
.con-box {
  background: #f7f7f7;
  padding: 15px;
  border-radius: 7px;
  margin-top: 5px;
  word-break: break-all;
  line-height: 16px;
  font-size: 12px;
  height: 200px;
}
.con-box .d-flex label {
  width: 100px;
}
.con-box .d-flex p {
  max-width: 60% !important;
  width: 100%;
}
.receiver {
  background-color: #fdecde !important;
  color: #7a4111 !important;
}
.receiver label {
  color: #7a4111 !important;
}
.receiver strong {
  color: #7a4111 !important;
}
.invoice {
  height: 95px;
  background: #f7f7f7;
  padding: 15px;
  border-radius: 7px;
  margin-top: 5px;
  word-break: break-all;
  line-height: 16px;
  font-size: 12px;
}
.contact {
  display: flex;
  background: #f7f7f7;
  padding: 15px;
  border-radius: 10px;
}
.contact span {
  background: #f6e4d4;
  display: inline-block;
  border-radius: 5px;
  padding: 2px;
  margin-right: 13px;
  padding-right: 12px;
}
.mb-3 {
  margin-bottom: 8px !important;
}
@media print {
  .print-link {
    display: none;
  }
  body {
    background-color: #ffffff;
    background-image: none;
    color: #000000;
  }
  #ad {
    display: none;
  }
  #leftbar {
    display: none;
  }
  #contentarea {
    width: 100%;
  }
  .miscbox {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2px 0px;
  }
}
@media only screen and (max-width: 991px) {
  .bill-A4 {
    width: 100%;
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 0px;
    background: #fff;
    border: 1px solid #333;
    padding: 15px;
    max-width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    overflow: scroll;
    height: calc(100vh - 40px);
  }
}
@media only screen and (max-width: 991px) {
  #waybillPage {
    width: 1100px;
    padding: 7px;
  }
}
@media print {
  html {
    zoom: 90%;

    @page {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @page :footer {
    display: none;
  }

  @page :header {
    display: none;
  }
}
.details-head {
  display: inline-block !important;
  width: 100%;
}
.details-head div {
  float: left;
  width: 25%;
}
.bar-code-style {
  display: inline-block !important;
  width: 100% !important;
  /* height: 69px; */
  text-align: center !important;
}
</style>
