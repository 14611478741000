<template>
  <v-app>
    <div id="app">
      <navbar />
      <div id="nav">
        <router-view />
      </div>
    </div>
  </v-app>
</template>

<script>
import Navbar from "@/components/Common/Navbar";

export default {
  components: {
    Navbar,
  },
};
</script>
<style>
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
</style>

<style lang="scss">
@font-face {
  font-family: "Open Sans";
  src: url("assets/OpenSans/OpenSans-Regular.ttf");
  src: url("assets/OpenSans/OpenSans-Bold.ttf");
  src: url("assets/OpenSans/OpenSans-ExtraBold.ttf");
  src: url("assets/OpenSans/OpenSans-Light.ttf");
  src: url("assets/OpenSans/OpenSans-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat-600";
  src: url("assets/OpenSans/OpenSans-SemiBold.ttf");
}
.Montserrat-600 {
  font-family: Montserrat-600;
}

@font-face {
  font-family: "Montserrat-700";
  src: url("assets/OpenSans/OpenSans-Bold.ttf");
}
.Montserrat-700 {
  font-family: Montserrat-700;
}

#app {
  font-family: "Open Sans", sans-serif;
  color: #2c3e50;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.muted {
  color: rgba(0, 0, 0, 0.5);
}

.bold {
  font-weight: bold;
  letter-spacing: 0.025rem;
}

.bolder {
  font-weight: 600;
}

.boldest {
  font-weight: 700;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.heading {
  font-size: 18px;
}

.sub-headline {
  font-size: 20px;
}

.nospace {
  margin: 0;
  padding: 0 !important;
}

.nomargin {
  margin: 0;
}

.automargin {
  margin: auto;
}

.nopadding {
  padding: 0 !important;
}

.bg-clr {
  background: #fcfcfc;
}

.heading {
  font-size: 16px;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.primary-clr {
  color: #f58b00;
}

.primary-bg-clr {
  background: #f58b00 !important;
  color: white !important;
}
.trukkin-theme.darken-2 {
  background-color: #f68d46 !important;
  border-color: #f68b43 !important;
}
</style>
