import { StorageKeys } from "@/constants/constants";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      loading: false,
      showScreen: true,
    };
  },
  created: function () {
    this.checkPermission();
  },
  methods: {
    checkPermission() {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.$http
        .post(`${rbacUrl}/checkUserPermission`, {
          userId: localStorage.getItem("userId"),
        })
        .then((res) => {
          if (!res.data.data) {
            this.loading = false;
          }
          let old = res.data.data.roles;
          old = old.map((x) => x.role.roleName.toLowerCase());
          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          roles = roles.map((x) => x.role.roleName.toLowerCase());
          old.sort();
          roles.sort();

          if (old.toString() !== roles.toString()) {
            this.showScreen = false;
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            Swal.fire({
              title: "Oops!",
              text: "Your permissions have changed .",
              type: "error",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              this.webViewLogout();
              localStorage.setItem("first-load", true);
              this.$router.replace("/");
              location.reload();
            });
          }
          if (old.toString() == roles.toString()) {
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
};
