<template>
  <v-container>
    <v-alert
      outline
      type="error"
      :value="error !== null"
      style="font-size:13px"
      transition="slide-y"
      >{{ error }}</v-alert
    >
  </v-container>
</template>

<script>
import { StorageKeys, WorkingCountries } from "../../constants/constants";
import { accessTokenLogin } from "../../constants/api-urls";
import { EventBus } from "../../event-bus.js";
export default {
  data() {
    return {
      error: null,
      userId: null,
    };
  },
  created() {
    this.$eventBus.$emit("show-nav-items", false);

    this.accessToken();
  },
  methods: {
    accessToken() {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.error = null;
      if (this.$route.params.id) {
        let Url = accessTokenLogin;
        delete this.axios.defaults.headers.common["token"];
        let token = this.$route.params.id;
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          webToken: this.$route.params.id,
          webView: true,
        };
        this.axios.post(this.constants.apiUrl + Url, body, config).then(
          (response) => {
            const { statusCode, data } = response.data;
            const { accessToken } = data;
            localStorage.setItem("user_Id", data._id);
            localStorage.setItem(StorageKeys.SessionKey, this.$route.params.id);
            localStorage.setItem("userId", data.userId);
            localStorage.setItem("user", data.name);
            localStorage.setItem("role", data.roleType);
            localStorage.setItem("email", data.email);
            if (data.email) {
              localStorage.setItem("isWebView", "true");
            }
            this.userId = data.userId;
            this.check();
            if (data.workingCountry.length) {
              let accessibleCountries = [];
              WorkingCountries.forEach((key, index) => {
                if (data.workingCountry.indexOf(key.value) >= 0) {
                  accessibleCountries.push(key);
                }
              });
              if (accessibleCountries.length) {
                accessibleCountries[0].isSelected = true;
                if (accessibleCountries[1]) {
                  accessibleCountries[1].isSelected = false;
                }
                if (accessibleCountries[2]) {
                  accessibleCountries[2].isSelected = false;
                }
              }
              localStorage.setItem(
                "workingCountries",
                JSON.stringify(accessibleCountries)
              );

              EventBus.$emit("setCountries", accessibleCountries);
            } else {
              this.setError(
                "You do not have access to any country! Please contact admin"
              );

              return false;
              this.loading = false;
            }
          },
          (error) => {}
        );
      } else {
        this.$eventBus.$emit("show-nav-items", false);
        this.$router.replace("*");
      }
    },
    check() {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.$http
        .post(`${rbacUrl}/checkUserPermission`, {
          userId: this.userId,
        })
        .then((res) => {
          localStorage.setItem("permissions", JSON.stringify(res.data.data));
          if (!res.data.data) {
          }
          let router = this.$router;
          this.$eventBus.$emit("show-nav-items", true);
          this.$router.replace("/sales");
        })
        .catch((e) => {
          this.setError(
            "Failed to redirect" +
              (this.data && this.data.message ? ":" + this.data.message : "")
          );
        });
    },
    setError(error) {
      this.error = error;
      // setTimeout(() => (this.error = null), 5000);
    },
  },
};
</script>

<style></style>
