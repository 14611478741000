import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import { StorageKeys } from "@/constants/constants.js";

Vue.use(VueAxios, axios);
const sessionKey = localStorage.getItem(StorageKeys.SessionKey);
if (sessionKey) {
  axios.defaults.headers.common["token"] = sessionKey;
}
let API_END_POINT = process.env.VUE_APP_API_URL;
let ACl_API_END_POINT = process.env.VUE_APP_RBAC_URL;

export const ApiService = {
  postData(url, body = {}) {
    delete axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };
    return axios.post(API_END_POINT + url, body, config);
  },
  postDataAcl(url, body = {}) {
    delete axios.defaults.headers.common["token"];
    return axios.post(ACl_API_END_POINT + url, body);
  },
  getData(url) {
    delete axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };
    return axios.get(API_END_POINT + url, config);
  },
  getInvoiceDetails(url) {
    // delete axios.defaults.headers.common["token"];
    // let token = localStorage.getItem(StorageKeys.SessionKey);
    // let config = {
    //   headers: {
    //     authorization: `bearer ${token}`,
    //   },
    // };
    return axios.get(API_END_POINT + url);
  },
};
