export const jobListApiUrl = "/dmsAdmin/jobList";
export const activityLogs = "/dmsAdmin/viewActivityLogs";
export const activityLogsCustomer = "/customerPayment/activityLog";

export const bookingStats = "/dmsAdmin/dubaiBookings";
export const assetForm = "/dmsAdmin/assetHandoverForm";
export const driverInfoForm = "/dmsAdmin/driverDetails";
export const addAdditonalCharges = "/dmsAdmin/addAdditionalCharges";
export const checkCreditLimit = "/dmsAdmin/checkCreditLimit";
export const additonalChargesAssignmentList = "/dmsAdmin/assignments2";
export const uploadImage = "/dmsAdmin/uploadImage";
export const editAdditonalCharges = "/dmsAdmin/editAdditionalCharges";
export const notificationList = "/commentRoute/getNotificationList";
export const editJobPrice = "/dmsAdmin/editJobPrice";
export const editAdvance = "/dmsAdmin/editAdvance";
export const generateAdvance = "/dmsAdmin/generateAdvance";
export const getAdditionalChargeList = "/dmsAdmin/get-additional-chargeName?";
export const receieveCustomerPayment = "/customerPayment/payment/receive";

export const adjustCustomerAmount = "/customerPayment/payment/adjust";
export const markOff = "/customerPayment/payment/markoffList";

export const passbookAdjust = "/customerPayment/payment/passBookWithAdjust";

export const lossToTrukkin = "/customerPayment/payment/lossTTEntry";
export const recieveAdjust = "/customer/payment/receive-and-adjust";
export const recieveBankLedger = "/customer-payment/bank-details-config";

export const adjustCustomerPayment = "/customerPayment/payment/receive";

export const acceptReceievePayment = "/customerPayment/payment/action";
export const acceptLossPayment = "/customerPayment/payment/lossTTEntryApprove";

export const editDriverPrice = "/dmsAdmin/editDriverPrice";
export const assignmentPayment = "/dmsAdmin/assignmentPayment";
export const settleFnfRequest = "/dmsAdmin/settleFnfRequest";
export const sendMessage = "/dmsAdmin/sendMessage";
export const trackingStatus = "/dmsAdmin/trackingStatus";
export const adjustStatus = "/customerPayment/payment/ListByEntry";

export const assignmentForEditDriverPrice =
  "/dmsAdmin/assignmentForEditDriverPrice";
export const addProofOfDelivery = "/dmsAdmin/addProofOfDeliveryForAssignment";
export const accessTokenLogin = "/dmsAdmin/accessTokenLogin";
export const getAllRoles = "/getAllRoles";
export const getAllOperations = "/getAllOperations";
export const assignRoletoUserType = "/assignRoletoUserType";
export const assignOperationtoRole = "/assignOperationtoRole";
export const getAllUserType = "/getAllUserType";
export const updateUserType = "/updateUserType";
export const updateRole = "/updateRole";
export const updateOperation = "/updateOperation";
export const rejectShipmentDocument = "/dmsAdmin/rejectShipmentDocument";
export const getUserById = "/admin/getUserById";
export const getLanguage = "/getLanguage";
export const CustomerListApi = "/customerPayment/list";

export const salesSectionApiUrl = {
  inquiryList: "/sales/inquiryList",
  checkRecreateInquiry: "/sales/checkRecreateInquiry",
  checkCreditLimit: "/sales/checkCreditLimit",
  changeStatus: "/sales/changeStatus",
  createInquiry: "/sales/createInquiry",
  inquiryDetails: "/sales/inquiryDetails",
  viewInquiryAddress: "/dmsAdmin/viewInquiryAddress",
  editInquiry: "/sales/editInquiry",
  dashboard: "/sales/dashboard",
  executiveList: "/sales/executiveList",
  addShipmentType: "/dmsAdmin/addShipmentType",
  getSingleShipmentType: "/dmsAdmin/getSingleShipmentType",
  editShipmentType: "/dmsAdmin/editShipmentType",
};
export const user = {
  getWeightedUserType: "/getWeightedUserType",
  assignPermissiontoUser: "/assignPermissiontoUser",
  getUserDetails: "/listAdminById",
  saveActivityForRoles: "/admin/saveActivityForRoles",
  checkUserPermission: "/checkUserPermission",
  assignedRoletoUserType: "/assignedRoletoUserType",
};
export const waybillSectionApiUrl = {
  waybillDetail: "/dmsAdmin/waybill",
  waybillDetailWithoutAuth: "/dmsAdmin/waybillWithoutAuth",
};
export const imeiSectionApiUrl = {
  imeiList: "/dmsAdmin/assetListing",
  addImei: "/dmsAdmin/addAsset",
};
export const tenderSectionApiUrl = {
  tenderList: "/dmsAdmin/tenderSection",
  reopenBid: "/dmsAdmin/reoOpenJobFromBid",
  withDrawBid: "/dmsAdmin/withDrawJobFromBid",
  viewBid: "/dmsAdmin/transporterRequests",
};

export const transporter = {
  transporterDetails: "/dmsAdmin/transporterDetails",
  editTransporter: "/dmsAdmin/editTransporter",
  blockAndVerifyDriver: "/dmsAdmin/blockAndVerifyDriver",
  transporterForDriverDetail: "/dmsAdmin/transporterForDriverDetail",
  verifyTender: "/dmsAdmin/verifyTender",
  blockTransporter: "/dmsAdmin/blockTransporter",
};

export const driver = {
  driverListCount: "/dmsAdmin/driverListCount",
};
export const requestCallbackSectionApiUrl = {
  callbackList: "/dmsAdmin/requestCallbackBookings",
  acknowledgeCallback: "/dmsAdmin/ackTicket",
  closeTicket: "/dmsAdmin/closeTicket",
};
export const addDriverDoc = {
  getShipmentDeliveryDocTypes: "/dmsAdmin/getShipmentDeliveryDocTypes",
  uploadShipmentDeliveryDoc: "/dmsAdmin/uploadShipmentDeliveryDoc",
};
export const driverProfilePdf = "/dmsAdmin/newcreateDriverPdf";
export const bidResponse = {
  acceptDriverBid: "/dmsAdmin/acceptDriverBid",
  bidList: "/dmsAdmin/bidList",
};
export const ChargesPopup = {
  getCharges: "/dmsAdmin/getCharges",
  addCharges: "/dmsAdmin/addCharges",
};
export const jobDocument = {
  getShipmentTypeForPopUP: "/dmsAdmin/getShipmentTypeForPopUP",
  uploadShipmentDocument: "/dmsAdmin/uploadShipmentDocument",
};

export const milestoneList = "/dmsAdmin/milestoneList";
export const editMarkedOff = "/customerPayment/payment/markoffEdit";

export const editComment = "/dmsAdmin/editComments";

export const milestoneListDetails = "/dmsAdmin/milestoneList";
export const additionalChargeListDetails =
  "/dmsAdmin/get-all-additional-chargeName";

export const removeMilestone = "/dmsAdmin/removeMilestone";
export const editAdditionalCharge = "/dmsAdmin/edit-additional-chargeName";

export const driverAvailablityCheck = "/dmsAdmin/checkDriverAvailability";

export const gpsCollectionPopup = "/dmsAdmin/collectGpsDevice";
export const generateWorkOrder = "/dmsAdmin/generateWorkOrder";
export const generatePurchaseInvoice = "/dmsAdmin/generatePurchaseInvoice";
export const generatePurchaseOrder = "/dmsAdmin/generatePurchaseOrder";
export const generateSaleOrder = "/dmsAdmin/generateSaleOrder";

export const driverDocPdf = "/dmsAdmin/driverDocPdf";
export const removeDriver = "/dmsAdmin/removeDriver";
export const editInvoiceDay = "/dmsAdmin/editInvoiceDay";
export const generateSalesInvoice = "/dmsAdmin/generateSalesInvoice";
export const sendSMStoDriver = "/dmsAdmin/sendSMStoDriver";
export const searchDriverByJob = "/dmsAdmin/searchDriverByJob";

export const updateJob = "/dmsAdmin/updateJob";
export const markShipmentDocStatus = "/dmsAdmin/markShipmentDocStatus";
export const deleteAdditionalCharges = "/dmsAdmin/deleteAdditionalCharges";
export const getShipmentDeliveryDoc = "/dmsAdmin/getShipmentDeliveryDoc";
export const sendQuote = "/dmsAdmin/sendQuote";
export const addNewMilestone = "/dmsAdmin/addNewMilestone";
export const addNewAdditionalCharge = "/dmsAdmin/additional-charge-name";

export const updateAssignments = "/dmsAdmin/updateAssignMents";
export const updateAssignmentsMilestone = "/dmsAdmin/updateAssignMilestone";
export const cancelAssignments = "/dmsAdmin/cancelAssignment";
export const completeAssignments = "/dmsAdmin/completeAssignment";
export const readyToPayFinalPayment = "/dmsAdmin/readyToPayFinalPayment";
export const acceptBid = "/dmsAdmin/acceptBid";
export const editFinalPaymentDriverDetails =
  "/dmsAdmin/editFinalPaymentDriverDetails";
export const editLabel = "/dmsAdmin/editLabel";
export const getPaymentProofs = "/dmsAdmin/getPaymentProofs";
export const viewAssetDetails = "/dmsAdmin/viewAssetDetails";
export const assignAsset = "/dmsAdmin/assignAsset";
export const unassignAsset = "/dmsAdmin/unassignAsset";
export const verifyCustomerAccount = "/dmsAdmin/verifyCustomerAccount";
export const readyToPayAdvance = "/dmsAdmin/readyToPayAdvance";
export const getCommentList = "/commentRoute/getCommentList";
export const userList = "/commentRoute/userList";
export const addComment = "/commentRoute/addComment";
export const deleteShipmentType = "/dmsAdmin/deleteShipmentType";
export const verifyShipmentDocument = "/dmsAdmin/verifyShipmentDocument";

export const procurement = {
  jobDataForPopup: "/dmsAdmin/jobDataForPopup",
  BidTransporterDriverList: "/newDriverFlow/BidTransporterDriverList",
  sendBidToTransportAndDriver: "/newDriverFlow/sendBidToTransportAndDriver",
  sendQuotePriceToSales: "/dmsAdmin/sendQuotePriceToSales",
};

export const custDetails = "/dmsAdmin/custDetails";
export const createJob = "/dmsAdmin/createJob";
export const newjobList = "/dmsAdmin/newjobList?";
export const bidList = "/dmsAdmin/bidList";
export const editCustomer = "/dmsAdmin/editCustomer";

export const sendJobToTransport = "/dmsAdmin/sendJobToTransport";
export const editCreditLimit = "/dmsAdmin/editCreditLimit";

export const jobsProfileAssignmentList = {
  unassignTransporter: "/dmsAdmin/unassignTransporter",
  journeyDistance: "/dmsAdmin/jouneyDistance",
  smartRecipent: "/dmsAdmin/smartRecipent",
  changeDocumnetStatus: "/dmsAdmin/changeDocStatus",
  sendCommunication: "/dmsAdmin/sendCommunication",
  updateTrackStatusToCust: "/dmsAdmin/updateTrackStatusToCust",
};
