<template>
  <v-flex
    v-if="!breakline"
    :class="noborder ? 'mx-1 my-2' : 'box mx-1 my-2'"
    :xs12="fullwidth"
    :xs6="halfwidth"
  >
    <div style="display: flex; justify-content: space-between">
      <div
        :style="{ flex: `1 1 ${engww}px` }"
        :xs3="noborder"
        class="text-align-left float-left"
      >
        <span class="pagetitle">{{ eng }}</span>
      </div>
      <div
        style="flex: 2 2 0px"
        xs6
        class="text-align-center"
        :class="noborder ? 'underline' : null"
      >
        <span class="value">{{ val }}</span>
      </div>
      <div
        :style="{ flex: `1 1 ${araww}px` }"
        :xs3="noborder"
        class="text-align-right float-right"
      >
        <span class="pagetitle">{{ ara }}</span>
      </div>
    </div>
  </v-flex>
  <v-flex v-else xs6 />
</template>

<script>
export default {
  props: {
    engww: {
      type: String,
      default: "60",
    },
    araww: {
      type: String,
      default: "60",
    },
    eng: String,
    ara: String,
    val: String,
    fullwidth: {
      type: Boolean,
      default: false,
    },
    halfwidth: {
      type: Boolean,
      default: false,
    },
    breakline: {
      type: Boolean,
      default: false,
    },
    noborder: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.pagetitle {
  color: rgba(0, 0, 0, 0.74);
  padding: 0px 10px;
  font-weight: bolder;
}

.value {
  font-weight: bold;
}

.underline {
  border-bottom: 1px solid #484848;
}

.text-align-right {
  text-align: right;
}

.box {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 5px 10px;
}

@media print {
  .box {
    padding: 10px;
  }
}
</style>
