<template>
  <v-menu
    transition="slide-x-transition"
    :close-on-content-click="true"
    offset-y
    class="profile-drop"
  >
    <v-toolbar-side-icon slot="activator">
      <v-icon @click="getNotifications()">notifications</v-icon>
    </v-toolbar-side-icon>
    <v-card max-width="475" width="420">
      <v-toolbar color="grey--text" flat>
        <v-toolbar-title>Notifications </v-toolbar-title>
        <div style="position: absolute; right: 0">
          <v-btn @click="closeMenu($event)" flat to="/notifications"
            >View All</v-btn
          >
        </div>
      </v-toolbar>
      <v-flex xs12 style="text-align: end">
        <v-btn
          class="ma-2"
          outline
          color="orange"
          :disabled="!count || count == 0"
          @click="markAllRead($event)"
        >
          Mark all as read
        </v-btn>
      </v-flex>
      <v-list class="scroll" v-if="notificationListing.length">
        <v-list-tile
          class="text-overlap list-noti"
          :class="!item.isRead ? 'dark-color' : ''"
          v-for="item in notificationListing"
          @click="!item.isRead ? markRead(item, $event) : ''"
          :key="item._id"
        >
          <label v-if="!item.isRead" class="__dot"></label>

          <v-list-tile-title
            v-if="item.type == 1"
            @click="checkViewJob(item.jobData.jobId)"
            target="_blank"
            class="line-height-set"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>

            <p class="tag-gray d-flex justify-content-between">
              <span v-if="item.senderdData"
                >Added by: {{ item.senderdData.name }}</span
              >
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            v-else-if="item.type == 4"
            style="cursor: pointer"
            @click="checkTender(item.jobData.jobModel)"
            class="line-height-set"
          >
            <p class="gray-dark">{{ item.message }}</p>

            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>

          <v-list-tile-title
            v-else-if="item.type == 33"
            @click="redirectCustomerReciept()"
            style="cursor: pointer"
            class="line-height-set"
          >
            <p class="gray-dark">{{ item.message }}</p>

            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>

          <v-list-tile-title
            @click="redirectApproval('sales')"
            target="_blank"
            style="cursor: pointer"
            class="line-height-set cursor"
            v-else-if="item.type == 3"
          >
            <p class="gray-dark">{{ item.message }}</p>

            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectNotification('procurements')"
            target="_blank"
            style="cursor: pointer"
            class="line-height-set cursor"
            v-else-if="item.type == 8"
          >
            <p class="gray-dark">{{ item.message }}</p>

            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectNotification('request-callback')"
            style="cursor: pointer; height: auto !important"
            class="line-height-set cursor"
            v-else-if="item.type == 9"
          >
            <p class="gray-dark">{{ item.message }}</p>

            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right"> {{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectApproval('job')"
            target="_blank"
            style="cursor: pointer"
            class="line-height-set cursor"
            v-else-if="item.type == 2"
          >
            <p class="gray-dark">{{ item.message }}</p>

            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="
              redirectTransDriver(
                item.transporterData.transporterId,
                item.transporterData._id,
                item.workingCountry
              )
            "
            target="_blank"
            style="cursor: pointer"
            class="line-height-set cursor"
            v-else-if="item.type == 7"
          >
            <p class="gray-dark">{{ item.message }}</p>

            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectjobDetail(item.jobData.jobId)"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 10"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectNotification('transporter')"
            target="_blank"
            style="cursor: pointer"
            class="line-height-set cursor"
            v-else-if="item.type == 11"
          >
            <p class="gray-dark">{{ item.message }}</p>

            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectAccounts2()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 12"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectassignmentview()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 13"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectAccounts()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 17"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectAccounts()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 14"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectCustomers()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 18"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectNotification('sales')"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 19"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectNotification('sales')"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 22"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectDrivers()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 23"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectCustomers()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 21"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectjobs()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 24"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectAccounts2()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 25"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectAccounts2()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 26"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="
              redirectAccountsDriver(
                item.assignmentIdNumber,
                item.workingCountry
              )
            "
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 27"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="
              redirectAccountsDriver(
                item.assignmentIdNumber,
                item.workingCountry
              )
            "
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 32"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectjobs()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 28"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectAccounts2()"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 29"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
          <v-list-tile-title
            @click="redirectProof(item.assignmentId)"
            target="_blank"
            class="line-height-set cursor"
            v-else-if="item.type == 30"
            style="cursor: pointer"
          >
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>

          <v-list-tile-title class="line-height-set" v-else>
            <p class="gray-dark">{{ item.message }}</p>
            <p class="tag-gray d-flex justify-content-between">
              <span></span>
              <span class="text-right">{{ getUtcTime(item.createdAt) }}</span>
            </p>
          </v-list-tile-title>
        </v-list-tile>
      </v-list>
      <v-list v-else>
        <v-list-tile>
          <v-list-tile-title style="text-align: center"
            >No data available.</v-list-tile-title
          >
        </v-list-tile>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
import moment from "moment";
import { notificationList } from "@/constants/api-urls.js";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
export default {
  data() {
    return {
      opeartionName: "",
      operationName1: "",
      notificationListing: [],
      workingCountries: [],
      workingCountry: [],
      btnValue: true,
      shown: false,
    };
  },
  watch: {},
  created() {
    this.$eventBus.$on("notify", (val) => {
      if (
        this.notificationListing.length &&
        this.$route.name != "notifications" &&
        this.shown
      ) {
        this.getNotifications();
      }
    });
  },
  props: {
    selectedCountry: Array,
    jobCommentsUEA: Number,
    jobCommentsKSA: Number,
    jobCommentsPAK: Number,
    jobCommentsUAELA: Number,
    count: Number,
  },

  methods: {
    redirectProof(id) {
      this.$router.push(`/documents/${id}?type=Driver_Proof`);
    },
    async checkViewJob(jobId) {
      this.operationName = "view-details-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.$eventBus.$emit("view-details", true);
        this.$router.push(`/jobs/${jobId.toString()}/view`);
        this.$eventBus.$emit("refresh-job");
      } else {
        return;
      }
    },
    redirectAccounts2() {
      this.$router.push(`/accounts/advance`).catch(() => {});
    },
    redirectjobs() {
      this.$router.push(`/assignments`);
    },
    redirectassignmentview(transporterId) {
      this.$router.push(`/assignments/`).catch(() => {});
    },
    redirectApproval(type) {
      this.$router.push(`/approvals?type=${type}`).catch(() => {});
    },
    redirectCustomerReciept() {
      this.$router.push(`/accounts/customerPayment`).catch(() => {});
    },
    redirectjobDetail(jobId) {
      this.$router.push(`/jobs/${jobId}`);
    },
    redirectDrivers() {
      this.$router.push(`/drivers`);
    },
    redirectAccounts() {
      this.$router.push(`/accounts`);
    },
    redirectAccountsDriver(assignmentId, workingCountry) {
      this.$router
        .push(
          `/accounts/advance?type=${assignmentId}&country=${workingCountry}`
        )
        .catch(() => {});
    },

    redirectCustomers() {
      this.$router.push(`/customers?type=false`);
    },
    redirectDrivers() {
      this.$router.push(`/drivers`);
    },
    redirectTransDriver(transporterId, _id, workingCountry) {
      this.$router.push(
        `/transporter/driver/${transporterId}/${_id}/${workingCountry}`
      );
    },
    async checkTender(model) {
      this.$router.push(`/tender/${model}`);
    },
    redirectNotification(type) {
      this.$router.push(`/${type}`);
    },
    getUtcTime(date) {
      return moment(date).format(" ll LT");
    },

    markAllRead(e) {
      e.stopPropagation();
      let url = `/commentRoute/v1/readNotification`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      if (localStorage.getItem("workingCountries")) {
        this.workingCountry = [];
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry.push(key.value);
          }
        });
      }
      let body = {
        workingCountry: this.workingCountry,
        isReadAll: true,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.data.data) {
            this.getNotifications();
          }
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },
    markRead(data) {
      let url = `/commentRoute/v1/readNotification`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      if (localStorage.getItem("workingCountries")) {
        this.workingCountry = [];
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry.push(key.value);
          }
        });
      }
      let body = {
        workingCountry: this.workingCountry,
        _id: data._id,
        isReadAll: false,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.data.data) {
            this.getNotifications();
          }
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },
    getNotifications() {
      setTimeout(() => {
        var objDiv = document.getElementsByClassName("scroll");
        for (var i = 0; i < objDiv.length; i++) {
          objDiv[i].scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      }, 1000);

      this.$emit("unread-count");
      this.shown = true;
      this.closeOnContentClick = false;
      let url = notificationList;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      if (localStorage.getItem("workingCountries")) {
        this.workingCountry = [];
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry.push(key.value);
          }
        });
      }

      let body = {
        workingCountry: this.workingCountry,
        pageNo: "1",
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.data.data) {
            this.$emit("removeDot");
            this.notificationListing = response.data.data.list;
            this.notificationListing;
          }
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },
  },
};
</script>
<style lang="scss">
.text-overlap .v-list__tile {
  height: auto !important;
}
</style>

<style scoped>
.dark-color {
  background-color: #fef1e9;
}
label.__dot {
  width: 8px;
  height: 8px;

  background: #f47824;
  border-radius: 50px;
  position: relative;
  right: 10px;
}
.text-right {
  text-align: right;
}
</style>
