export const StorageKeys = {
  SessionKey: "trukkin###sessionToken",
};
export const CancellationType = [
  { text: "Duplicate Job", value: "Duplicate Job" },
  { text: "Truck Breakdown", value: "Truck Breakdown" },
  { text: "Client Cancelled", value: "Client Cancelled" },
  { text: "Driver Cancelled", value: "Driver Cancelled" },
  { text: "Documentation Issue", value: "Documentation Issue" },
  {
    text: "Rate not accepted by customer",
    value: "Rate not accepted by customer",
  },
  { text: "Back dated Job", value: "Back dated Job" },
  { text: "Wrong Customer rate", value: "Wrong Customer rate" },
  {
    text: "Truck did not report at agreed time",
    value: "Truck did not report at agreed time",
  },
  { text: "Other’s", value: "Other’s" },
];

export const JobTypes = [
  { text: "Land Freight", value: "1", disabled: false },
  { text: "Air Freight", value: "2", disabled: false },
  { text: "Sea Freight", value: "3", disabled: false },
];
export const JobListTypes = [
  { text: "Land Freight", value: "landFreight" },
  { text: "Air Freight", value: "airFreight" },
  { text: "Sea Freight", value: "seaFreight" },
];
export const AssignmentStatusList = [
  { text: "Driver Assigned", value: "Driver Assigned" },
  { text: "In-Transit", value: "In-Transit" },
  { text: "Shipment Offloaded & POD Awaited", value: "Shipement Offloaded" },
  { text: "Document Received", value: "Doc Received" },
  { text: "Payment Pending", value: "Payment Pending" },
  { text: "Payment Done", value: "Payment Done" },
];
export const AdvanceStatusList = [
  { text: "Requested", value: "Requested" },
  { text: "Ready To Pay", value: "Ready To Pay" },
  { text: "Paid", value: "Paid" },
  { text: "Cancelled", value: "Cancelled" },
  { text: "Rejected", value: "Rejected" },
];
export const AdvanceStatusaccountsList = [
  { text: "Requested", value: "Requested" },
  { text: "Ready To Pay", value: "Ready To Pay" },
  { text: "Paid", value: "Paid" },
  { text: "Rejected", value: "Rejected" },
];
export const JobBooking = [
  { text: "Land Freight", value: "1", disabled: false },
  { text: "Air Freight", value: "2", disabled: false },
  { text: "Sea Freight", value: "3", disabled: false },
];
export const JobGeoType1 = [
  { text: "International", value: "1", disabled: false },
  { text: "Domestic", value: "2", disabled: false },
];
export const JobBookingType1 = [
  { text: "Load Movement", value: "2", disabled: false },
  { text: "Documentation", value: "1", disabled: false },
];
export const JobGeoType = [
  { text: "International", value: "International", disabled: false },
  { text: "Domestic", value: "Domestic", disabled: false },
];
export const JobBookingType = [
  { text: "Load Movement", value: "Load Movement", disabled: false },
  { text: "Documentation", value: "Documentation", disabled: false },
];
export const StakeHolders = [
  { text: "Customer", value: "Customer", disabled: false },
  { text: "Driver", value: "Driver", disabled: false },
  { text: "Transporter", value: "Transporter", disabled: false },
  { text: "Other Agent", value: "Other Agent", disabled: false },
];
export const JobMode1 = [
  { text: "Import", value: "1", disabled: false },
  { text: "Export", value: "2", disabled: false },
  { text: "Local", value: "3", disabled: false },
];
export const JobMode = [
  { text: "Import", value: "import", disabled: false },
  { text: "Export", value: "export", disabled: false },
  { text: "Local", value: "local", disabled: false },
];
export const JobModeInternational = [
  { text: "Import", value: "1", disabled: false },
  { text: "Export", value: "2", disabled: false },
];
export const realBookOrders = [
  { text: "Sales Order", value: "salesOrder", disabled: false },
  // {text: "Purchase Order", value : 'purchaseOrder',  disabled: false},
];
export const UserTypes = [
  {
    text: "Land Freight",
    value: "1",
  },
  { text: "Air Freight", value: "2" },
  { text: "Sea Freight", value: "3" },
];
export const TruckTypes = [
  { text: "Container Trucks", value: "1", parent: "0" },
  { text: "Curtain Trailer", value: "2", parent: "0" },
  { text: "Dyna Trucks-10 Tons", value: "3", parent: "0" },
  { text: "Flatbed", value: "4", parent: "2" },
  { text: "Pickup Trucks", value: "6", parent: "1" },
  { text: "Pickup Truck-10 Tons", value: "7", parent: "1" },
  { text: "Pickup Truck-3 Tons", value: "8", parent: "1" },
  { text: "Reefers", value: "9,", parent: "5" },
  { text: "Trailer(40ft)", value: "10", parent: "0" },
  { text: "Big Truck", value: "11", parent: "0" },
  { text: "Small Truck", value: "12", parent: "0" },
  { text: "Box Truck", value: "13", parent: "0" },

  // ------------- new values ---------------------------------------------------------------------//
  { text: "12m Flatbed", value: "21", parent: "2" },
  { text: "13.6m Flatbed", value: "22", parent: "2" },
  { text: "15.3 Flatbeds", value: "23", parent: "2" },
  { text: "13.6m Curtain side", value: "24", parent: "3" },
  { text: "15.3m Curtain side", value: "25", parent: "3" },
  { text: "13.6m Closed Box", value: "26", parent: "4" },
  { text: "15.3m Closed Box", value: "27", parent: "4" },
  { text: "13.6m Reefer", value: "28", parent: "5" },
  { text: "15.3m Reefer", value: "29", parent: "5" },
  { text: "40 Ton Lowbed", value: "30", parent: "6" },
  { text: "60 Ton Lowbed", value: "31", parent: "6" },
  { text: "80 Ton Lowbed", value: "32", parent: "6" },
  { text: "40 Ton Tipper", value: "33", parent: "7" },
  { text: "80 Ton Tipper", value: "34", parent: "7" },
  { text: "1 Ton Pickup", value: "14", parent: "1" },
  { text: "3 Tons Open Pickup", value: "15", parent: "1" },
  { text: "3 Tons Closed Pickup", value: "16", parent: "1" },
  { text: "4.2 Tons Closed Pickup", value: "17", parent: "1" },
  { text: "7 Tons Open Pickup", value: "18", parent: "1" },
  { text: "10 Tons Pickup", value: "19", parent: "1" },
  { text: "10 Tons Open Pickup", value: "20", parent: "1" },

  // ------------ new values for PAK -----------------
  { text: "Suzuki Pickup", value: "35", parent: "1" },
  { text: "Shehzore Pickup", value: "36", parent: "1" },
  { text: "Trailer(20ft)", value: "37", parent: "0" },
  { text: "Mazda(16ft)", value: "38", parent: "0" },
];

export const TruckArray = [
  {
    text: "Pickups",
    value: "1",
    sub: [
      { text: "1 Ton Pickup", value: "14" },
      { text: "3 Tons Open Pickup", value: "15" },
      { text: "3 Tons Closed Pickup", value: "16" },
      { text: "4.2 Tons Closed Pickup", value: "17" },
      { text: "7 Tons Open Pickup", value: "18" },
      { text: "10 Tons Pickup", value: "19" },
      { text: "10 Tons Open Pickup", value: "20" },
      { text: "Suzuki Pickup", value: "35" },
      { text: "Shehzore Pickup", value: "36" },
    ],
  },
  {
    text: "Flatbeds",
    value: "2",
    sub: [
      { text: "12m Flatbed", value: "21" },
      { text: "13.6m Flatbed", value: "22" },
      { text: "15.3 Flatbeds", value: "23" },
    ],
  },
  {
    text: "Curtain Side",
    value: "3",
    sub: [
      { text: "13.6m Curtain side", value: "24" },
      { text: "15.3m Curtain side", value: "25" },
    ],
  },
  {
    text: "Closed Box",
    value: "4",
    sub: [
      { text: "13.6m Closed Box", value: "26" },
      { text: "15.3m Closed Box", value: "27" },
    ],
  },
  {
    text: "Reefers",
    value: "5",
    sub: [
      { text: "13.6m Reefer", value: "28" },
      { text: "15.3m Reefer", value: "29" },
    ],
  },
  {
    text: "Lowbed",
    value: "6",
    sub: [
      { text: "40 Ton Lowbed", value: "30" },
      { text: "60 Ton Lowbed", value: "31" },
      { text: "80 Ton Lowbed", value: "32" },
    ],
  },
  {
    text: "Tipper",
    value: "7",
    sub: [
      { text: "40 Ton Tipper", value: "33" },
      { text: "80 Ton Tipper", value: "34" },
    ],
  },
  {
    text: "Others",
    value: "0",
    sub: [
      { text: "Container Trucks", value: "1" },
      { text: "Curtain Trailer", value: "2" },
      { text: "Dyna Trucks-10 Tons", value: "3" },
      { text: "Flatbed", value: "4" },
      { text: "Pickup Trucks", value: "6" },
      { text: "Pickup Truck-10 Tons", value: "7" },
      { text: "Pickup Truck-3 Tons", value: "8" },
      { text: "Reefers", value: "9" },
      { text: "Trailer(40ft)", value: "10" },
      { text: "Trailer(20ft)", value: "37" },
      { text: "Mazda(16ft)", value: "38" },
      { text: "Big Truck", value: "11" },
      { text: "Small Truck", value: "12" },
      { text: "Box Truck", value: "13" },
    ],
  },
];

export const AirArray = [
  {
    text: "General",
    value: "1",
    sub: [
      { text: "General 1", value: "1" },
      { text: "General 2", value: "2" },
    ],
  },
  { text: "Special", value: "2", sub: [{ text: "Special 1", value: "3" }] },
];

export const AirTypes = [
  { text: "General 1", value: "1", parent: "1" },
  { text: "General 2", value: "2", parent: "1" },
  { text: "Special 1", value: "3", parent: "2" },
];
export const SeaArray = [
  {
    text: "Bulk carriers",
    value: "1",
    sub: [
      { text: " Bulk carriers Type 1", value: "1" },
      { text: " Bulk carriers Type 2", value: "2" },
    ],
  },
  {
    text: "General Cargo ships",
    value: "2",
    sub: [{ text: " General Cargo ships Type 1", value: "3" }],
  },
  {
    text: "Container ships",
    value: "3",
    sub: [{ text: "Container ships Type 1", value: "4" }],
  },
  {
    text: "Tankers",
    value: "4",
    sub: [{ text: "Tankers Type 1", value: "5" }],
  },
  { text: "Barges", value: "5", sub: [{ text: "Barges Type 1", value: "6" }] },
];
export const SeaTypes = [
  { text: " Bulk carriers Type 1", value: "1", parent: "1" },
  { text: " Bulk carriers Type 2", value: "2", parent: "1" },
  { text: " General Cargo ships Type 1", value: "3", parent: "2" },
  { text: "Container ships Type 1", value: "4", parent: "3" },
  { text: "Tankers Type 1", value: "5", parent: "4" },
  { text: "Barges Type 1", value: "6", parent: "5" },
];

export const NatureOfCargos = [
  { value: 1, text: "ALUMINIUM" },
  { value: 2, text: "ANIMAL FOOD" },
  { value: 3, text: "BEAM" },
  { value: 4, text: "BOXES" },
  { value: 5, text: "BUILDING MATERIALS" },
  { value: 6, text: "CARPET" },
  { value: 7, text: "CEMENT" },
  { value: 8, text: "CHEMICAL" },
  { value: 9, text: "CHROMITE SAND BAGS" },
  { value: 10, text: "CIGRATTES" },
  { value: 11, text: "COFFEE" },
  { value: 12, text: "CONTAINER" },
  { value: 13, text: "COSMETICS" },
  { value: 14, text: "DAIRY" },
  { value: 15, text: "DRY RAW MATERIALS" },
  { value: 16, text: "ELECTRONICS" },
  { value: 17, text: "ESCALATORS" },
  { value: 18, text: "FINISHED GOODS" },
  { value: 19, text: "FISH N MEAT" },
  { value: 20, text: "FLEXIBLE DUCK" },
  { value: 21, text: "FOOD ITEMS" },
  { value: 22, text: "FRUITS N VEGETABLES" },
  { value: 23, text: "FURNITURES" },
  { value: 24, text: "GENERAL" },
  { value: 25, text: "GLASS" },
  { value: 26, text: "HARDWARES N SANITARY" },
  { value: 27, text: "HOUSE SHIFTING" },
  { value: 28, text: "ICE CREAM" },
  { value: 29, text: "INDUSTRIAL GOODS" },
  { value: 30, text: "KETCHUP" },
  { value: 31, text: "LIQUID" },
  { value: 32, text: "MARBLES N TILES" },
  { value: 33, text: "MICRO SILICA (NON HAZ)" },
  { value: 34, text: "MINERAL WATER" },
  { value: 35, text: "MISC." },
  { value: 36, text: "NUT BOLTS" },
  { value: 37, text: "OIL FIELD EQUIPMENT" },
  { value: 38, text: "OTHERS" },
  { value: 39, text: "PAPER PALLETS" },
  { value: 40, text: "PAPER ROLLS" },
  { value: 41, text: "PAPERS" },
  { value: 42, text: "PERLITE" },
  { value: 43, text: "PHARMACEUTICALS" },
  { value: 44, text: "PIPES AND FITTINGS" },
  { value: 45, text: "PLASTIC DANA" },
  { value: 46, text: "PLASTIC MATERIAL" },
  { value: 47, text: "PLYWOOD" },
  { value: 48, text: "SPICES" },
  { value: 49, text: "STEEL PRODUCTS" },
  { value: 50, text: "STOCK" },
  { value: 51, text: "STRING" },
  { value: 52, text: "SUGAR" },
  { value: 53, text: "TEXTILE N HANDLOOM" },
  { value: 54, text: "TEXTILE" },
  { value: 55, text: "UNDEFINED" },
  { value: 56, text: "USED CLOTHES" },
  { value: 57, text: "CABLE DRUM" },
  { value: 58, text: "CABLE ROLL" },
  { value: 59, text: "FOOD INDUSTRY" },
  { value: 60, text: "INDUSTRIAL RAW MATERIAL" },
  { value: 61, text: "OIL & GAS EQUIPMENT" },
  { value: 62, text: "STEEL MANUFACTURING" },
  { value: 63, text: "AUTOMOTIVE INDUSTRY" },
  { value: 64, text: "PAINTS AND COATINGS" },
  { value: 65, text: "CONSTRUCTION INDUSTRY" },
  { value: 66, text: "CHEMICALS & CHEMICAL RAW MATERIALS" },
  { value: 67, text: "OTHERS" },
];
export const NatureOfCargosAdd = [
  { value: 59, text: "FOOD INDUSTRY" },
  { value: 60, text: "INDUSTRIAL RAW MATERIAL" },
  { value: 61, text: "OIL & GAS EQUIPMENT" },
  { value: 62, text: "STEEL MANUFACTURING" },
  { value: 63, text: "AUTOMOTIVE INDUSTRY" },
  { value: 64, text: "PAINTS AND COATINGS" },
  { value: 65, text: "CONSTRUCTION INDUSTRY" },
  { value: 66, text: "CHEMICALS & CHEMICAL RAW MATERIALS" },
  { value: 67, text: "OTHERS" },
];
export const PackingTypes = [
  { value: 1, text: "BOXED" },
  { value: 2, text: "LOOSE" },
  { value: 3, text: "LOOSE, BUNDLES, AND PALLETS" },
  { value: 4, text: "JUMBO BAGS" },
  { value: 5, text: "BULK" },
  { value: 6, text: "PALLET" },
  { value: 7, text: "CONTAINER" },
  { value: 8, text: "SMALL CONTAINER" },
  { value: 9, text: "GENERAL" },
  { value: 10, text: "SUGAR" },
  { value: 11, text: "STRING" },
  { value: 12, text: "PACKED" },
  { value: 13, text: "BUNDLES" },
  { value: 14, text: "OTHERS" },
];
export const TruckPrefix = [
  { value: "DXB", text: "DXB" },
  { value: "AUH", text: "AUH" },
  { value: "SHJ", text: "SHJ" },
  { value: "AJM", text: "AJM" },
  { value: "FUJ", text: "FUJ" },
  { value: "UAQ", text: "UAQ" },
  { value: "AAN", text: "AAN" },
  { value: "RAK", text: "RAK" },
  { value: "Others", text: "Others" },
  { value: "AH", text: "AH" },
];
export const Currencies = [
  { value: "AED", text: "AED" },
  { value: "SAR", text: "SAR" },
];
export const Banks = [
  { value: "Mashreq Bank PSJC", text: "Mashreq Bank PSJC" },
  { value: "EMIRATES NBD Ban", text: "EMIRATES NBD Ban" },
  { value: "Alinma Bank - Riyad", text: "Alinma Bank - Riyad" },
  { value: "Alinma Bank - Jeddah", text: "Alinma Bank - Jeddah" },
  { value: "Alinma Bank - Dammam", text: "Alinma Bank - Dammam" },
];
export const WorkingCountries = [
  { value: "UAE", currency: "AED", isSelected: false },
  { value: "PAK", currency: "PKR", isSelected: false },
  { value: "KSA", currency: "SAR", isSelected: false },
  { value: "UAE LA", currency: "AED", isSelected: false },
  { value: "OMAN", currency: "OMR", isSelected: false },
];
export const EnquirySources = [
  { value: "Email", text: "Email" },
  { value: "Call", text: "Call" },
];
export const ShipmentTypes = [
  { value: "Local", text: "Local" },
  { value: "Cross Country", text: "Cross Country" },
  { value: "Cross Continent", text: "Cross Continent" },
];
export const countries = [
  {
    country_code: "AF",
    country_name: "Afghanistan",
    dialing_code: "+93",
    index: 0,
  },
  {
    country_code: "AL",
    country_name: "Albania",
    dialing_code: "+355",
    index: 1,
  },
  {
    country_code: "DZ",
    country_name: "Algeria",
    dialing_code: "+213",
    index: 2,
  },
  {
    country_code: "AS",
    country_name: "American Samoa",
    dialing_code: "+1",
    index: 3,
  },
  {
    country_code: "AD",
    country_name: "Andorra",
    dialing_code: "+376",
    index: 4,
  },
  {
    country_code: "AO",
    country_name: "Angola",
    dialing_code: "+244",
    index: 5,
  },
  {
    country_code: "AI",
    country_name: "Anguilla",
    dialing_code: "+1",
    index: 6,
  },
  {
    country_code: "AG",
    country_name: "Antigua",
    dialing_code: "+1",
    index: 7,
  },
  {
    country_code: "AR",
    country_name: "Argentina",
    dialing_code: "+54",
    index: 8,
  },
  {
    country_code: "AM",
    country_name: "Armenia",
    dialing_code: "+374",
    index: 9,
  },
  {
    country_code: "AW",
    country_name: "Aruba",
    dialing_code: "+297",
    index: 10,
  },
  {
    country_code: "AU",
    country_name: "Australia",
    dialing_code: "+61",
    index: 11,
  },
  {
    country_code: "AI",
    country_name: "Austria",
    dialing_code: "+43",
    index: 12,
  },
  {
    country_code: "AZ",
    country_name: "Azerbaijan",
    dialing_code: "+994",
    index: 13,
  },
  {
    country_code: "BH",
    country_name: "Bahrain",
    dialing_code: "+973",
    index: 14,
  },
  {
    country_code: "BD",
    country_name: "Bangladesh",
    dialing_code: "+880",
    index: 15,
  },
  {
    country_code: "BB",
    country_name: "Barbados",
    dialing_code: "+1",
    index: 16,
  },
  {
    country_code: "BY",
    country_name: "Belarus",
    dialing_code: "+375",
    index: 17,
  },
  {
    country_code: "BE",
    country_name: "Belgium",
    dialing_code: "+32",
    index: 18,
  },
  {
    country_code: "BZ",
    country_name: "Belize",
    dialing_code: "+501",
    index: 19,
  },
  {
    country_code: "BJ",
    country_name: "Benin",
    dialing_code: "+229",
    index: 20,
  },
  {
    country_code: "BM",
    country_name: "Bermuda",
    dialing_code: "+1",
    index: 21,
  },
  {
    country_code: "BT",
    country_name: "Bhutan",
    dialing_code: "+975",
    index: 22,
  },
  {
    country_code: "BO",
    country_name: "Bolivia",
    dialing_code: "+591",
    index: 23,
  },
  {
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    dialing_code: "+387",
    index: 24,
  },
  {
    country_code: "BW",
    country_name: "Botswana",
    dialing_code: "+267",
    index: 25,
  },
  {
    country_code: "BR",
    country_name: "Brazil",
    dialing_code: "+55",
    index: 26,
  },
  {
    country_code: "IO",
    country_name: "British Indian Ocean Territory",
    dialing_code: "+246",
    index: 27,
  },
  {
    country_code: "VG",
    country_name: "British Virgin Islands",
    dialing_code: "+1",
    index: 28,
  },
  {
    country_code: "BN",
    country_name: "Brunei",
    dialing_code: "+673",
    index: 29,
  },
  {
    country_code: "BG",
    country_name: "Bulgaria",
    dialing_code: "+359",
    index: 30,
  },
  {
    country_code: "BF",
    country_name: "Burkina Faso",
    dialing_code: "+226",
    index: 31,
  },
  {
    country_code: "MM",
    country_name: "Burma Myanmar",
    dialing_code: "+95",
    index: 32,
  },
  {
    country_code: "BI",
    country_name: "Burundi",
    dialing_code: "+257",
    index: 33,
  },
  {
    country_code: "KH",
    country_name: "Cambodia",
    dialing_code: "+855",
    index: 34,
  },
  {
    country_code: "CM",
    country_name: "Cameroon",
    dialing_code: "+237",
    index: 35,
  },
  {
    country_code: "CA",
    country_name: "Canada",
    dialing_code: "+1",
    index: 36,
  },
  {
    country_code: "CV",
    country_name: "Cape Verde",
    dialing_code: "+238",
    index: 37,
  },
  {
    country_code: "KY",
    country_name: "Cayman Islands",
    dialing_code: "+1",
    index: 38,
  },
  {
    country_code: "CF",
    country_name: "Central African Republic",
    dialing_code: "+236",
    index: 39,
  },
  {
    country_code: "ID",
    country_name: "Chad",
    dialing_code: "+235",
    index: 40,
  },
  {
    country_code: "CL",
    country_name: "Chile",
    dialing_code: "+56",
    index: 41,
  },
  {
    country_code: "CN",
    country_name: "China",
    dialing_code: "+86",
    index: 42,
  },
  {
    country_code: "CO",
    country_name: "Colombia",
    dialing_code: "+57",
    index: 43,
  },
  {
    country_code: "KM",
    country_name: "Comoros",
    dialing_code: "+269",
    index: 44,
  },
  {
    country_code: "CK",
    country_name: "Cook Islands",
    dialing_code: "+682",
    index: 45,
  },
  {
    country_code: "CR",
    country_name: "Costa Rica",
    dialing_code: "+506",
    index: 46,
  },
  {
    country_code: "CI",
    country_name: "Cote d'Ivoire",
    dialing_code: "+225",
    index: 47,
  },
  {
    country_code: "HR",
    country_name: "Croatia",
    dialing_code: "+385",
    index: 48,
  },
  {
    country_code: "CU",
    country_name: "Cuba",
    dialing_code: "+53",
    index: 49,
  },
  {
    country_code: "CY",
    country_name: "Cyprus",
    dialing_code: "+357",
    index: 50,
  },
  {
    country_code: "CZ",
    country_name: "Czech Republic",
    dialing_code: "+420",
    index: 51,
  },
  {
    country_code: "CD",
    country_name: "Democratic Republic of Congo",
    dialing_code: "+243",
    index: 52,
  },
  {
    country_code: "DK",
    country_name: "Denmark",
    dialing_code: "+45",
    index: 53,
  },
  {
    country_code: "DJ",
    country_name: "Djibouti",
    dialing_code: "+253",
    index: 54,
  },
  {
    country_code: "DM",
    country_name: "Dominica",
    dialing_code: "+1",
    index: 55,
  },
  {
    country_code: "DO",
    country_name: "Dominican Republic",
    dialing_code: "+1",
    index: 56,
  },
  {
    country_code: "EC",
    country_name: "Ecuador",
    dialing_code: "+593",
    index: 57,
  },
  {
    country_code: "EG",
    country_name: "Egypt",
    dialing_code: "+20",
    index: 58,
  },
  {
    country_code: "SV",
    country_name: "El Salvador",
    dialing_code: "+503",
    index: 59,
  },
  {
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    dialing_code: "+240",
    index: 60,
  },
  {
    country_code: "ER",
    country_name: "Eritrea",
    dialing_code: "+291",
    index: 61,
  },
  {
    country_code: "EE",
    country_name: "Estonia",
    dialing_code: "+372",
    index: 62,
  },
  {
    country_code: "ET",
    country_name: "Ethiopia",
    dialing_code: "+251",
    index: 63,
  },
  {
    country_code: "FK",
    country_name: "Falkland Islands",
    dialing_code: "+500",
    index: 64,
  },
  {
    country_code: "FO",
    country_name: "Faroe Islands",
    dialing_code: "+298",
    index: 65,
  },
  {
    country_code: "FM",
    country_name: "Federated States of Micronesia",
    dialing_code: "+691",
    index: 66,
  },
  {
    country_code: "FJ",
    country_name: "Fiji",
    dialing_code: "+679",
    index: 67,
  },
  {
    country_code: "FI",
    country_name: "Finland",
    dialing_code: "+358",
    index: 68,
  },
  {
    country_code: "FR",
    country_name: "France",
    dialing_code: "+33",
    index: 69,
  },
  {
    country_code: "GF",
    country_name: "French Guiana",
    dialing_code: "+594",
    index: 70,
  },
  {
    country_code: "PF",
    country_name: "French Polynesia",
    dialing_code: "+689",
    index: 71,
  },
  {
    country_code: "GA",
    country_name: "Gabon",
    dialing_code: "+241",
    index: 72,
  },
  {
    country_code: "GE",
    country_name: "Georgia",
    dialing_code: "+995",
    index: 73,
  },
  {
    country_code: "DE",
    country_name: "Germany",
    dialing_code: "+49",
    index: 74,
  },
  {
    country_code: "GH",
    country_name: "Ghana",
    dialing_code: "+233",
    index: 75,
  },
  {
    country_code: "GI",
    country_name: "Gibraltar",
    dialing_code: "+350",
    index: 76,
  },
  {
    country_code: "GR",
    country_name: "Greece",
    dialing_code: "+30",
    index: 77,
  },
  {
    country_code: "GL",
    country_name: "Greenland",
    dialing_code: "+299",
    index: 78,
  },
  {
    country_code: "GD",
    country_name: "Grenada",
    dialing_code: "+1",
    index: 79,
  },
  {
    country_code: "GP",
    country_name: "Guadeloupe",
    dialing_code: "+590",
    index: 80,
  },
  {
    country_code: "GU",
    country_name: "Guam",
    dialing_code: "+1",
    index: 81,
  },
  {
    country_code: "GT",
    country_name: "Guatemala",
    dialing_code: "+502",
    index: 82,
  },
  {
    country_code: "GN",
    country_name: "Guinea",
    dialing_code: "+224",
    index: 83,
  },
  {
    country_code: "GW",
    country_name: "Guinea-Bissau",
    dialing_code: "+245",
    index: 84,
  },
  {
    country_code: "GY",
    country_name: "Guyana",
    dialing_code: "+592",
    index: 85,
  },
  {
    country_code: "HT",
    country_name: "Haiti",
    dialing_code: "+509",
    index: 86,
  },
  {
    country_code: "HN",
    country_name: "Honduras",
    dialing_code: "+504",
    index: 87,
  },
  {
    country_code: "HK",
    country_name: "Hong Kong",
    dialing_code: "+852",
    index: 88,
  },
  {
    country_code: "HU",
    country_name: "Hungary",
    dialing_code: "+36",
    index: 89,
  },
  {
    country_code: "IS",
    country_name: "Iceland",
    dialing_code: "+354",
    index: 90,
  },
  {
    country_code: "IN",
    country_name: "India",
    dialing_code: "+91",
    index: 91,
  },
  {
    country_code: "ID",
    country_name: "Indonesia",
    dialing_code: "+62",
    index: 92,
  },
  {
    country_code: "IR",
    country_name: "Iran",
    dialing_code: "+98",
    index: 93,
  },
  {
    country_code: "IQ",
    country_name: "Iraq",
    dialing_code: "+964",
    index: 94,
  },
  {
    country_code: "IE",
    country_name: "Ireland",
    dialing_code: "+353",
    index: 95,
  },
  {
    country_code: "IL",
    country_name: "Israel",
    dialing_code: "+972",
    index: 96,
  },
  {
    country_code: "IT",
    country_name: "Italy",
    dialing_code: "+39",
    index: 97,
  },
  {
    country_code: "JM",
    country_name: "Jamaica",
    dialing_code: "+1",
    index: 98,
  },
  {
    country_code: "JP",
    country_name: "Japan",
    dialing_code: "+81",
    index: 99,
  },
  {
    country_code: "JO",
    country_name: "Jordan",
    dialing_code: "+962",
    index: 100,
  },
  {
    country_code: "KZ",
    country_name: "Kazakhstan",
    dialing_code: "+7",
    index: 101,
  },
  {
    country_code: "KE",
    country_name: "Kenya",
    dialing_code: "+254",
    index: 102,
  },
  {
    country_code: "KI",
    country_name: "Kiribati",
    dialing_code: "+686",
    index: 103,
  },
  {
    country_code: "XK",
    country_name: "Kosovo",
    dialing_code: "+381",
    index: 104,
  },
  {
    country_code: "KW",
    country_name: "Kuwait",
    dialing_code: "+965",
    index: 105,
  },
  {
    country_code: "KG",
    country_name: "Kyrgyzstan",
    dialing_code: "+996",
    index: 106,
  },
  {
    country_code: "LA",
    country_name: "Laos",
    dialing_code: "+856",
    index: 107,
  },
  {
    country_code: "LV",
    country_name: "Latvia",
    dialing_code: "+371",
    index: 108,
  },
  {
    country_code: "LB",
    country_name: "Lebanon",
    dialing_code: "+961",
    index: 109,
  },
  {
    country_code: "LS",
    country_name: "Lesotho",
    dialing_code: "+266",
    index: 110,
  },
  {
    country_code: "LR",
    country_name: "Liberia",
    dialing_code: "+231",
    index: 111,
  },
  {
    country_code: "LY",
    country_name: "Libya",
    dialing_code: "+218",
    index: 112,
  },
  {
    country_code: "LI",
    country_name: "Liechtenstein",
    dialing_code: "+423",
    index: 113,
  },
  {
    country_code: "LT",
    country_name: "Lithuania",
    dialing_code: "+370",
    index: 114,
  },
  {
    country_code: "LU",
    country_name: "Luxembourg",
    dialing_code: "+352",
    index: 115,
  },
  {
    country_code: "MO",
    country_name: "Macau",
    dialing_code: "+853",
    index: 116,
  },
  {
    country_code: "MK",
    country_name: "Macedonia",
    dialing_code: "+389",
    index: 117,
  },
  {
    country_code: "MG",
    country_name: "Madagascar",
    dialing_code: "+261",
    index: 118,
  },
  {
    country_code: "MW",
    country_name: "Malawi",
    dialing_code: "+265",
    index: 119,
  },
  {
    country_code: "MY",
    country_name: "Malaysia",
    dialing_code: "+60",
    index: 120,
  },
  {
    country_code: "MV",
    country_name: "Maldives",
    dialing_code: "+960",
    index: 121,
  },
  {
    country_code: "ML",
    country_name: "Mali",
    dialing_code: "+223",
    index: 122,
  },
  {
    country_code: "MT",
    country_name: "Malta",
    dialing_code: "+356",
    index: 123,
  },
  {
    country_code: "MH",
    country_name: "Marshall Islands",
    dialing_code: "+692",
    index: 124,
  },
  {
    country_code: "MQ",
    country_name: "Martinique",
    dialing_code: "+596",
    index: 125,
  },
  {
    country_code: "MR",
    country_name: "Mauritania",
    dialing_code: "+222",
    index: 126,
  },
  {
    country_code: "MU",
    country_name: "Mauritius",
    dialing_code: "+230",
    index: 127,
  },
  {
    country_code: "YT",
    country_name: "Mayotte",
    dialing_code: "+262",
    index: 128,
  },
  {
    country_code: "MX",
    country_name: "Mexico",
    dialing_code: "+52",
    index: 129,
  },
  {
    country_code: "MD",
    country_name: "Moldova",
    dialing_code: "+373",
    index: 130,
  },
  {
    country_code: "MC",
    country_name: "Monaco",
    dialing_code: "+377",
    index: 131,
  },
  {
    country_code: "MN",
    country_name: "Mongolia",
    dialing_code: "+976",
    index: 132,
  },
  {
    country_code: "ME",
    country_name: "Montenegro",
    dialing_code: "+382",
    index: 133,
  },
  {
    country_code: "MS",
    country_name: "Montserrat",
    dialing_code: "+1",
    index: 134,
  },
  {
    country_code: "MA",
    country_name: "Morocco",
    dialing_code: "+212",
    index: 135,
  },
  {
    country_code: "MZ",
    country_name: "Mozambique",
    dialing_code: "+258",
    index: 136,
  },
  {
    country_code: "NA",
    country_name: "Namibia",
    dialing_code: "+264",
    index: 137,
  },
  {
    country_code: "NR",
    country_name: "Nauru",
    dialing_code: "+674",
    index: 138,
  },
  {
    country_code: "NP",
    country_name: "Nepal",
    dialing_code: "+977",
    index: 139,
  },
  {
    country_code: "NL",
    country_name: "Netherlands",
    dialing_code: "+31",
    index: 140,
  },
  {
    country_code: "AN",
    country_name: "Netherlands Antilles",
    dialing_code: "+599",
    index: 141,
  },
  {
    country_code: "NC",
    country_name: "New Caledonia",
    dialing_code: "+687",
    index: 142,
  },
  {
    country_code: "NZ",
    country_name: "New Zealand",
    dialing_code: "+64",
    index: 143,
  },
  {
    country_code: "NI",
    country_name: "Nicaragua",
    dialing_code: "+505",
    index: 144,
  },
  {
    country_code: "NE",
    country_name: "Niger",
    dialing_code: "+227",
    index: 145,
  },
  {
    country_code: "NG",
    country_name: "Nigeria",
    dialing_code: "+234",
    index: 146,
  },
  {
    country_code: "NU",
    country_name: "Niue",
    dialing_code: "+683",
    index: 147,
  },
  {
    country_code: "NF",
    country_name: "Norfolk Island",
    dialing_code: "+672",
    index: 148,
  },
  {
    country_code: "KP",
    country_name: "North Korea",
    dialing_code: "+850",
    index: 149,
  },
  {
    country_code: "MP",
    country_name: "Northern Mariana Islands",
    dialing_code: "+1",
    index: 150,
  },
  {
    country_code: "NO",
    country_name: "Norway",
    dialing_code: "+47",
    index: 151,
  },
  {
    country_code: "OM",
    country_name: "Oman",
    dialing_code: "+968",
    index: 152,
  },
  {
    country_code: "PK",
    country_name: "Pakistan",
    dialing_code: "+92",
    index: 153,
  },
  {
    country_code: "PW",
    country_name: "Palau",
    dialing_code: "+680",
    index: 154,
  },
  {
    country_code: "PS",
    country_name: "Palestine",
    dialing_code: "+970",
    index: 155,
  },
  {
    country_code: "PA",
    country_name: "Panama",
    dialing_code: "+507",
    index: 156,
  },
  {
    country_code: "PG",
    country_name: "Papua New Guinea",
    dialing_code: "+675",
    index: 157,
  },
  {
    country_code: "PY",
    country_name: "Paraguay",
    dialing_code: "+595",
    index: 158,
  },
  {
    country_code: "PE",
    country_name: "Peru",
    dialing_code: "+51",
    index: 159,
  },
  {
    country_code: "PH",
    country_name: "Philippines",
    dialing_code: "+63",
    index: 160,
  },
  {
    country_code: "PL",
    country_name: "Poland",
    dialing_code: "+48",
    index: 161,
  },
  {
    country_code: "PT",
    country_name: "Portugal",
    dialing_code: "+351",
    index: 162,
  },
  {
    country_code: "PR",
    country_name: "Puerto Rico",
    dialing_code: "+1",
    index: 163,
  },
  {
    country_code: "QA",
    country_name: "Qatar",
    dialing_code: "+974",
    index: 164,
  },
  {
    country_code: "CG",
    country_name: "Republic of the Congo",
    dialing_code: "+242",
    index: 165,
  },
  {
    country_code: "RE",
    country_name: "Reunion",
    dialing_code: "+262",
    index: 166,
  },
  {
    country_code: "RO",
    country_name: "Romania",
    dialing_code: "+40",
    index: 167,
  },
  {
    country_code: "RU",
    country_name: "Russia",
    dialing_code: "+7",
    index: 168,
  },
  {
    country_code: "RW",
    country_name: "Rwanda",
    dialing_code: "+250",
    index: 169,
  },
  {
    country_code: "BL",
    country_name: "Saint Barthelemy",
    dialing_code: "+590",
    index: 170,
  },
  {
    country_code: "SH",
    country_name: "Saint Helena",
    dialing_code: "+290",
    index: 171,
  },
  {
    country_code: "KN",
    country_name: "Saint Kitts and Nevis",
    dialing_code: "+1",
    index: 172,
  },
  {
    country_code: "MF",
    country_name: "Saint Martin",
    dialing_code: "+590",
    index: 173,
  },
  {
    country_code: "PM",
    country_name: "Saint Pierre and Miquelon",
    dialing_code: "+508",
    index: 174,
  },
  {
    country_code: "VC",
    country_name: "Saint Vincent and the Grenadines",
    dialing_code: "+1",
    index: 175,
  },
  {
    country_code: "WS",
    country_name: "Samoa",
    dialing_code: "+685",
    index: 176,
  },
  {
    country_code: "SM",
    country_name: "San Marino",
    dialing_code: "+378",
    index: 177,
  },
  {
    country_code: "ST",
    country_name: "Sao Tome and Principe",
    dialing_code: "+239",
    index: 178,
  },
  {
    country_code: "SA",
    country_name: "Saudi Arabia",
    dialing_code: "+966",
    index: 179,
  },
  {
    country_code: "SN",
    country_name: "Senegal",
    dialing_code: "+221",
    index: 180,
  },
  {
    country_code: "RS",
    country_name: "Serbia",
    dialing_code: "+381",
    index: 181,
  },
  {
    country_code: "SC",
    country_name: "Seychelles",
    dialing_code: "+248",
    index: 182,
  },
  {
    country_code: "SL",
    country_name: "Sierra Leone",
    dialing_code: "+232",
    index: 183,
  },
  {
    country_code: "SG",
    country_name: "Singapore",
    dialing_code: "+65",
    index: 184,
  },
  {
    country_code: "SK",
    country_name: "Slovakia",
    dialing_code: "+421",
    index: 185,
  },
  {
    country_code: "SI",
    country_name: "Slovenia",
    dialing_code: "+386",
    index: 186,
  },
  {
    country_code: "SB",
    country_name: "Solomon Islands",
    dialing_code: "+677",
    index: 187,
  },
  {
    country_code: "SO",
    country_name: "Somalia",
    dialing_code: "+252",
    index: 188,
  },
  {
    country_code: "ZA",
    country_name: "South Africa",
    dialing_code: "+27",
    index: 189,
  },
  {
    country_code: "KR",
    country_name: "South Korea",
    dialing_code: "+82",
    index: 190,
  },
  {
    country_code: "ES",
    country_name: "Spain",
    dialing_code: "+34",
    index: 191,
  },
  {
    country_code: "LK",
    country_name: "Sri Lanka",
    dialing_code: "+94",
    index: 192,
  },
  {
    country_code: "LC",
    country_name: "St. Lucia",
    dialing_code: "+1",
    index: 193,
  },
  {
    country_code: "SD",
    country_name: "Sudan",
    dialing_code: "+249",
    index: 194,
  },
  {
    country_code: "SR",
    country_name: "Suriname",
    dialing_code: "+597",
    index: 195,
  },
  {
    country_code: "SZ",
    country_name: "Swaziland",
    dialing_code: "+268",
    index: 196,
  },
  {
    country_code: "SE",
    country_name: "Sweden",
    dialing_code: "+46",
    index: 197,
  },
  {
    country_code: "CH",
    country_name: "Switzerland",
    dialing_code: "+41",
    index: 198,
  },
  {
    country_code: "SY",
    country_name: "Syria",
    dialing_code: "+963",
    index: 199,
  },
  {
    country_code: "TW",
    country_name: "Taiwan",
    dialing_code: "+886",
    index: 200,
  },
  {
    country_code: "TJ",
    country_name: "Tajikistan",
    dialing_code: "+992",
    index: 201,
  },
  {
    country_code: "TZ",
    country_name: "Tanzania",
    dialing_code: "+255",
    index: 202,
  },
  {
    country_code: "TH",
    country_name: "Thailand",
    dialing_code: "+66",
    index: 203,
  },
  {
    country_code: "BS",
    country_name: "The Bahamas",
    dialing_code: "+1",
    index: 204,
  },
  {
    country_code: "GM",
    country_name: "The Gambia",
    dialing_code: "+220",
    index: 205,
  },
  {
    country_code: "TL",
    country_name: "Timor-Leste",
    dialing_code: "+670",
    index: 206,
  },
  {
    country_code: "TG",
    country_name: "Togo",
    dialing_code: "+228",
    index: 207,
  },
  {
    country_code: "TK",
    country_name: "Tokelau",
    dialing_code: "+690",
    index: 208,
  },
  {
    country_code: "TO",
    country_name: "Tonga",
    dialing_code: "+676",
    index: 209,
  },
  {
    country_code: "TT",
    country_name: "Trinidad and Tobago",
    dialing_code: "+1",
    index: 210,
  },
  {
    country_code: "TN",
    country_name: "Tunisia",
    dialing_code: "+216",
    index: 211,
  },
  {
    country_code: "TR",
    country_name: "Turkey",
    dialing_code: "+90",
    index: 212,
  },
  {
    country_code: "TM",
    country_name: "Turkmenistan",
    dialing_code: "+993",
    index: 213,
  },
  {
    country_code: "TC",
    country_name: "Turks and Caicos Islands",
    dialing_code: "+1",
    index: 214,
  },
  {
    country_code: "TV",
    country_name: "Tuvalu",
    dialing_code: "+688",
    index: 215,
  },
  {
    country_code: "UG",
    country_name: "Uganda",
    dialing_code: "+256",
    index: 216,
  },
  {
    country_code: "UA",
    country_name: "Ukraine",
    dialing_code: "+380",
    index: 217,
  },
  {
    country_code: "AE",
    country_name: "United Arab Emirates",
    dialing_code: "+971",
    index: 218,
  },
  {
    country_code: "GB",
    country_name: "United Kingdom",
    dialing_code: "+44",
    index: 219,
  },
  {
    country_code: "US",
    country_name: "United States",
    dialing_code: "+1",
    index: 220,
  },
  {
    country_code: "UY",
    country_name: "Uruguay",
    dialing_code: "+598",
    index: 221,
  },
  {
    country_code: "VI",
    country_name: "US Virgin Islands",
    dialing_code: "+1",
    index: 222,
  },
  {
    country_code: "UZ",
    country_name: "Uzbekistan",
    dialing_code: "+998",
    index: 223,
  },
  {
    country_code: "VU",
    country_name: "Vanuatu",
    dialing_code: "+678",
    index: 224,
  },
  {
    country_code: "VA",
    country_name: "Vatican City",
    dialing_code: "+39",
    index: 225,
  },
  {
    country_code: "VE",
    country_name: "Venezuela",
    dialing_code: "+58",
    index: 226,
  },
  {
    country_code: "VN",
    country_name: "Vietnam",
    dialing_code: "+84",
    index: 227,
  },
  {
    country_code: "WF",
    country_name: "Wallis and Futuna",
    dialing_code: "+681",
    index: 228,
  },
  {
    country_code: "YE",
    country_name: "Yemen",
    dialing_code: "+967",
    index: 229,
  },
  {
    country_code: "ZM",
    country_name: "Zambia",
    dialing_code: "+260",
    index: 230,
  },
  {
    country_code: "ZW",
    country_name: "Zimbabwe",
    dialing_code: "+263",
    index: 231,
  },
];
