export default {
  methods: {
    removeStorage(type) {
      //Comman local storage
      if (type) {
        localStorage.removeItem("webToken");
        localStorage.removeItem("activeMenu");
        localStorage.removeItem("permissions");
        localStorage.removeItem("isWebView");
        localStorage.removeItem("userId");
        localStorage.removeItem("user");
        localStorage.removeItem("role");
        localStorage.removeItem("cust-status");
        localStorage.removeItem("user_Id");
        localStorage.removeItem("email");
        localStorage.removeItem("unreadCount");
        localStorage.removeItem("workingCountries");
      }

      localStorage.removeItem("changeTabListing");
      //Jobs section local storage
      localStorage.removeItem("paginationUrlJob");
      localStorage.removeItem("searchJob");
      localStorage.removeItem("searchJobDriver");
      localStorage.removeItem("searchJobType");
      localStorage.removeItem("searchJobDriverType");
      localStorage.removeItem("subRealBook");
      localStorage.removeItem("subJobType");
      localStorage.removeItem("filterType");
      localStorage.removeItem("subGeoType");
      localStorage.removeItem("subJobMode");
      localStorage.removeItem("jobStatus");
      localStorage.removeItem("jobTabStatus");
      localStorage.removeItem("JobstartdateFilter");
      localStorage.removeItem("JobenddateFilter");
      //Procurement section local storage
      localStorage.removeItem("subJobTypePro");
      localStorage.removeItem("ProtartdateFilter");
      localStorage.removeItem("ProenddateFilter");
      localStorage.removeItem("searchProcurement");
      localStorage.removeItem("searchProcurementType");
      localStorage.removeItem("searchProcurementDriver");
      localStorage.removeItem("searchProcurementDriverType");
      localStorage.removeItem("paginationUrlProcure");
      //Driver section local storage
      localStorage.removeItem("payloadDriver");
      localStorage.removeItem("searchDriverPreferredLocation");
      localStorage.removeItem("searchDriverStatus");
      localStorage.removeItem("searchDriverType");
      localStorage.removeItem("searchDrivertext");
      localStorage.removeItem("DriverJobType");
      localStorage.removeItem("subTruckType");
      localStorage.removeItem("driverVerificationStatus");
      //Transporter Section local storage
      localStorage.getItem("searchTransporter");
      //Assignment Section local storage
      localStorage.removeItem("paginationUrlAssignment");
      localStorage.removeItem("TabStatusAssign");
      //Advance Section local Storage
      localStorage.removeItem("paginationUrlAdvance");
    },
  },
};
